function checkNameInArray(filterValues: any[], value:any) {
  if (!Array.isArray(filterValues) || !Array.isArray(value)) {
    return false; // Если filterValues или value не являются массивами, возвращаем false
  }

  for (const filterValue of filterValues) {
    for (const item of value) {
      if (item && typeof item === 'object' && item.name === filterValue) {
        return true; // Найдено совпадение, возвращаем true
      }
    }
  }

  return false; // Совпадений не найдено, возвращаем false
}

export const filterFunction = ({
  value,
  filterValues,
  isObject
}: {
  value: any;
  filterValues: any[];
  isObject?: boolean
}) => {
  if (!isObject) {
    return filterValues.includes(value);
  } else {
    return checkNameInArray(filterValues, value)
  }

};
