import { SearchIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Grid, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Text } from "@chakra-ui/react";
import { IMarketplaceMaterial, IMarketplaceMaterialOffer, ISingleMaterial } from "../../../api/marketplace/types";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { fetchAllMaterials, getObjectsFromLocalStorage, resetCurrentMaterial, saveObjectsToLocalStorage, setCurrentMaterial, updateMaterialOffer } from "../../../store/slices/marketplace";
import { formatAmount } from "../../../helpers/formatAmount";
import { ReactComponent as DeleteIcon } from "../../../images/svg/delete/delete-active.svg";
import { ReactComponent as DisableDeleteIcon } from "../../../images/svg/delete/delete-disable.svg";
import { Table } from "../../lib/table/Table";
import { MaterialItem } from "./MaterialItem";

const columnHelper = createColumnHelper<IMarketplaceMaterialOffer>();

type QuantityCellProps = {
    info: CellContext<IMarketplaceMaterialOffer, string>;
};

const QuantityCell = ({ info }: QuantityCellProps) => {
    const dispatch = useAppDispatch();


    const initialQuantity = info.row.original.quantity;

    const [isError, setIsError] = useState<boolean>(false)
    const [value, setValue] = useState(() => {
        const nextNumber = parseFloat(initialQuantity);
        return Number.isNaN(nextNumber) ? "0,00" : formatAmount(nextNumber);
    });

    useEffect(() => {
        const nextNumber = parseFloat(initialQuantity);
        setValue(Number.isNaN(nextNumber) ? "0,00" : formatAmount(nextNumber));
        setIsError(Number(info.row.original.quantity) > Number(info.row.original.amount))
    }, [initialQuantity, info.row.original.amount]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        changeValue(e.target.value)
    }

    const changeValue = (e: string) => {
        const rawValue = e.replace(/\s/g, "").replace(",", ".");
        const floatValue = parseFloat(rawValue);
        const newValue = Number.isNaN(floatValue) ? "0,00" : formatAmount(floatValue);

        if (newValue !== info.row.original.quantity && (floatValue <= parseFloat(info.row.original.amount) || Number.isNaN(floatValue)) && !(floatValue < 0)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = Number.isNaN(floatValue) ? "0,00" : String(floatValue);
            if (Number.isNaN(floatValue)) {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            } else {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * floatValue
                );
            }

            setIsError(false)
            let myOffers = getObjectsFromLocalStorage()
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        } else if (floatValue > parseFloat(info.row.original.amount)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = Number.isNaN(floatValue) ? "0,00" : String(floatValue);
            if (Number.isNaN(floatValue)) {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            } else {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * floatValue
                );
            }

            setIsError(true)
            let myOffers = getObjectsFromLocalStorage()
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        } else if (floatValue < 0 || Number.isNaN(floatValue)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = "0,00";
            if (Number.isNaN(floatValue)) {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            } else {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            }
            setValue(newValue)
            setIsError(true)
            let myOffers = getObjectsFromLocalStorage()
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        }
    };

    const getInputBorderColor = () => {
        if (isError) { return "#E8363D" }
        if (value !== "0,00") { return "#AAB0C1" }
        else { return "#E3E7EF" }
    }

    const isDisabled = Number(info.row.original.amount) === 0;

    return (
        <Flex key={`${info.row.original.guid}`} alignItems="center" gap="8px">
            <Input
                height="32px"
                width="100px"
                value={value}
                border="1px solid #E3E7EF"
                borderColor={getInputBorderColor()}
                onChange={handleChange}
                onBlur={handleBlur}
                errorBorderColor="#E8363D"
                isDisabled={isDisabled}
            />
            <Button 
                disabled={value === "0,00"} 
                minWidth="auto" 
                width="32px" 
                backgroundColor="transparent" 
                onClick={() => changeValue("0,00")} 
                height="32px" 
                p="6px" 
                border={`1px solid ${value === "0,00" ? "#E3E7EF" : "#F1868B"}`} 
                borderRadius="6px"
                isDisabled={isDisabled}
            >
                <Icon width="20px" height="20px" as={value === "0,00" ? DisableDeleteIcon : DeleteIcon} />
            </Button>
        </Flex>
    );
};

export const MarketPlaceCatalog = () => {

    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [searchValue, setSearchValue] = useState<string>("")
    const [filteredMaterials, setFilteredMaterials] = useState<IMarketplaceMaterial[] | null>([])
    const [isStartState, setIsStartState] = useState<boolean>(true)


    const { materials, currentMaterial } = useAppSelector(
        (state: RootState) => state.marketplace.marketplaceData
    );

    const handleMaterialClick = (material: ISingleMaterial) => {
        dispatch(setCurrentMaterial(material));
        setIsStartState(false)
    };

    let myObjects = getObjectsFromLocalStorage();

    const [isRender, setIsRender] = useState<boolean>(false)

    useEffect(() => {
        dispatch(fetchAllMaterials())
            .finally(() => {
                if (myObjects.length > 0 && !isRender) {
                    myObjects.forEach((el: any) => dispatch(updateMaterialOffer(el)))
                    setIsRender(true)
                }
            })
    }, [])


    useEffect(() => {
        if (materials) {
            const resultMaterials = findMatchingMaterials(materials, searchValue.toLowerCase());
            setFilteredMaterials(resultMaterials);
        }

    }, [materials]);


    function filterMaterials() {
        if (searchValue.length) {
            const resultMaterials = findMatchingMaterials(materials, searchValue.toLowerCase());
            setFilteredMaterials(resultMaterials);
            setIsStartState(resultMaterials.length > 0);
        } else {
            setFilteredMaterials(materials);
            setIsStartState(!!materials); // Более компактная проверка на null/undefined
        }
    }

    function findMatchingMaterials(materials: any[] | null, searchValue: string): any[] {
        const result: IMarketplaceMaterial[] = [];
    
        if (!materials) return result;
        if (searchValue.length === 0) return materials;
    
        for (const material of materials) {
            const childMatches = material.materials?.some((child:any) =>
                child.name.toLowerCase().includes(searchValue)
            );
    
            if (childMatches) {
                // Фильтруем дочерние элементы, соответствующие поиску
                const matchingChildren = material.materials?.filter((child:any) =>
                    child.name.toLowerCase().includes(searchValue)
                ) || [];
    
                // Добавляем только если есть подходящие дети
                if (matchingChildren.length > 0) {
                    result.push({ ...material, expanded: true, materials: matchingChildren }); // передаем отфильтрованные children
                }
            } else if (material.name.toLowerCase().includes(searchValue)) {
                result.push(material);
            }
        }
    
        return result;
    }
    function hasGuid(material:any, currentMaterial: any) {
        if (!material.materials) {
            return false; // Или throw new Error("material.materials is undefined"); в зависимости от логики
        }
        const targetGuid = currentMaterial?.guid;
        if (!targetGuid) {
            return false; // Если guid у currentMaterial отсутствует, считаем, что не найдено. Можно изменить логику.
        }
        return material.materials.some((item:any) => item.guid === targetGuid);
    }



    const columns = useMemo<Array<ColumnDef<IMarketplaceMaterialOffer, string>>>(
        () => [
            columnHelper.accessor("supplier_name", {
                header: "Поставщик",
                    cell: (info) => info.renderValue(),
                }),
                columnHelper.accessor("name", {
                    header: "Материал",
                    cell: (info) => info.renderValue(),
                }),
                columnHelper.accessor("article", {
                    header: "Артикул",
                    cell: (info) => info.renderValue(),
                }),
                columnHelper.accessor("description", {
                    header: "Описание",
                    cell: (info) => info.renderValue(),
                }),
                columnHelper.accessor("price", {
                    header: "Цена",
                    cell: (info) => formatAmount(String(info.getValue())),
                }),
                columnHelper.accessor("quantity",
                    {
                        header: "Количество",
                        cell: (info) => (
                            <QuantityCell
                                key={`${currentMaterial?.guid}-${info.row.original.guid}`}
                                info={info}
                            />
                        ),
                    }
                ),
                columnHelper.accessor("amount", {
                    header: "Остаток",
                    cell: (info) => info.getValue(),
                }),
                columnHelper.accessor("totalPrice",
                    {
                        header: "Сумма",
                        cell: (info) => info.getValue() || "0,00",
                    }
                ),
            ],
            [currentMaterial]
        );
    return (
        <Grid height={"100%"} gridTemplateColumns="360px 1fr" gap="16px">
            <Box>
                <InputGroup width="100%">
                    <InputLeftElement>
                        <SearchIcon color="#838A9D" />
                    </InputLeftElement>
                    <Input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        rounded="md"
                        borderColor="#E3E7EF"
                        placeholder="Найти"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                dispatch(resetCurrentMaterial())
                                filterMaterials();
                            }
                        }}
                    />
                    <InputRightElement onClick={filterMaterials} cursor="pointer" roundedTopRight="md" roundedBottomRight="md" backgroundColor="#E8363D">
                        <SearchIcon color="white" />
                    </InputRightElement>
                </InputGroup>
                <Box rounded="md" mt="16px" padding="8px 16px" border="1px solid #E3E7EF" maxH="74vh" overflowY="auto">
                    <Flex width="100%" alignItems="center" height="48px">
                        <Text
                            width="100%"
                            lineHeight="18px"
                            fontSize="14px"
                            fontWeight={400}
                            color="#8089A2"
                            padding="0 16px"
                            borderLeft="0.5px solid #E3E7EF"
                            borderRight="0.5px solid #E3E7EF"
                            mb="15px"
                        >
                            Материал
                        </Text>
                    </Flex>
                    {filteredMaterials &&
                        filteredMaterials.map((material, index) => ( // Используем index вместо id
                            <MaterialItem
                                key={index} // Используем index в качестве ключа
                                material={material}
                                handleMaterialClick={handleMaterialClick}
                                currentMaterial={currentMaterial}
                                expanded={material.expanded || hasGuid(material, currentMaterial)} // Передаем свойство expanded в MaterialItem
                            />
                        ))}

                </Box>
            </Box>
            <Box height={"100%"} minW="calc(100%)" maxW="calc(100%)">
                <Table
                    dblClickDisabled={true}
                    minHeight={"100%"}
                    style={{ "height": "80vh" }}
                    columns={columns} data={currentMaterial?.offers || []} IsMarketTable={true} isStartState={isStartState}> </Table>
            </Box>
        </Grid>
    )
}