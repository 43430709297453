import { RootState, useAppSelector } from "../../store";
import { Grid, GridItem } from "@chakra-ui/react";
import dayjs from "dayjs";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { StatusCell } from "../StatusCell/StatusCell";
import { formatAmount } from "../../helpers/formatAmount";

export function SingleContractInformationBlock() {
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );

  function validDate(){
    let result = ''
    if (currentContract?.expiration_date === null) {
      result = 'Нет данных';
    } else {
      result = currentContract?.expiration_date 
        ? dayjs(currentContract.expiration_date).format("DD.MM.YYYY") 
        : "";
    }
    return result;
  }
  
  return (
    <Grid gap={4} templateColumns="1fr 402px">
      <GridItem>
        <InformationBlock>
          <InformationItem title="Документ ПД">
            {currentContract?.name ?? ""}
          </InformationItem>
          <InformationItem title="Организация">
            {currentContract?.a101_org_name?? ""}
          </InformationItem>
          <InformationItem title="Срок действия">
            {validDate()}
          </InformationItem>
          <InformationItem title="Статус договора">
            <StatusCell value={currentContract?.status ?? ""} />
          </InformationItem>
          <InformationItem title="Исполнитель" isExecutor isDisabled={currentContract?.is_archived || currentContract?.status !== "Зарегистрирован"}>
            {currentContract?.executor ?? ""}
          </InformationItem>
        </InformationBlock>
      </GridItem>
      <GridItem>
        <InformationBlock>
          <InformationItem title="Сумма договора">
            {formatAmount(currentContract?.cost) ?? ""}
          </InformationItem>
          <InformationItem title="Сумма без НДС">
            {formatAmount(currentContract?.cost_without_vat) ?? ""}
          </InformationItem>
          <InformationItem title="Ставка НДС">
            {currentContract?.rate_vat ? `${currentContract?.rate_vat}%` : ""}
          </InformationItem>
          <InformationItem title="Сумма НДС">
            {formatAmount(currentContract?.amount_vat) ?? ""}
          </InformationItem>
        </InformationBlock>
      </GridItem>
      <GridItem>
        <InformationBlock>
          <InformationItem title="Описание объекта строительства">
            {currentContract?.description_construction_object ?? ""}
          </InformationItem>
          <InformationItem title="Проект">
            {currentContract?.project?.name ?? ""}
          </InformationItem>
          <InformationItem title="Ответственный за объект"></InformationItem>
        </InformationBlock>
      </GridItem>
    </Grid>
  );
}
