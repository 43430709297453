export function checkAndSetIsErrorRecursive(array1, array2) {
  let newArray = [];
  for (let i = 0; i < array1.length; i++) {
    const item1 = array1[i];
    let clonedItem = { ...item1 };

    // Проверяем TypeofWork и устанавливаем isError
    clonedItem.isError = array2.some((item2) => {
      return item1.TypeofWork === item2.TypeOfWork;
    });

    // Проверяем isMaterial и materialGuid и устанавливаем isError
    if (item1.isMaterial) {
      clonedItem.isError = array2.some((item2) => {
        return item2.materialGuid && item1.materialGuid === item2.materialGuid;
      });
    }

    if (item1.subRows && item1.subRows.length > 0) {
      //Создаем копию subRows, чтобы избежать мутации исходного объекта
      let clonedSubRows = JSON.parse(JSON.stringify(item1.subRows));
      clonedItem.subRows = checkAndSetIsErrorRecursive(clonedSubRows, array2);
    }
    newArray.push(clonedItem);
  }
  return newArray;
}
