import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  IContract,
  IContractMaterial,
  IContractMaterialsResponse,
  IContractsResponse,
  ILetterGuarantees,
  ILetterGuaranteesResponse,
  ISingleContractResponse,
  ISingleContractWorkCompletedAct,
  ISingleContractWorkCompletedActsResponse,
} from "../../api/contracts/types";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { IAccessAct, IAccessActsResponse } from "../../api/accessActs/types";
import { ISingleContractConstructionsResponse, ISingleContractPaymentsResponse, ISingleRemarkConstruction } from "../../api/remarks/types";
import { IPayment } from "../../api/payments/types";
import { IPrintFormResponse } from "../../api/directionLetters/types";
import { IFilesAttachResponse } from "../../api/files/types";
import { AxiosError } from "axios";
import { attachFiles } from "../../api/files";
import { ContractsEndpoints, Files } from "../../api/endpoints";
import { fetchFeedbackExecutor } from "../../api/contracts";
import { importMaterials } from "../../api/limitFenceCards";
import { v4 as uuidv4 } from 'uuid';

export interface ContractsState extends ICommonInitialState {
  contracts: IContract[];
  archiveContracts: IContract[];
  currentContract: IContract | null;
  currentContractWorkCompletedActs: ISingleContractWorkCompletedAct[];
  currentContractSingleWorkCompletedAct: ISingleContractWorkCompletedAct | null;
  currentContractAccessActs: IAccessAct[];
  currentContractConstructionRemarks: ISingleRemarkConstruction[];
  currentContractPayments: IPayment[];
  currentContractLettersGuarantees: ILetterGuarantees[];
  currentLetterGuarantees: string | null;
  currentContractMaterials: IContractMaterial[];
  currentContractId: number | null
}

const initialState: ContractsState = {
  contracts: [],
  archiveContracts: [],
  currentContract: null,
  currentContractWorkCompletedActs: [],
  currentContractSingleWorkCompletedAct: null,
  currentContractAccessActs: [],
  currentContractConstructionRemarks: [],
  currentContractPayments: [],
  currentContractLettersGuarantees: [],
  currentLetterGuarantees: null,
  currentContractMaterials: [],
  currentContractId: null,
  isLoading: false,
  error: null,
};

export const fetchContracts = createAsyncThunkInstance<
  IContractsResponse,
  undefined
>("contracts/fetchContracts", api.contracts.getContracts);

export const fetchArchiveContracts = createAsyncThunkInstance<
  IContractsResponse,
  undefined
>("contracts/fetchArchiveContracts", api.contracts.getArchiveContracts);

export const fetchSingleContract = createAsyncThunkInstance<
  ISingleContractResponse,
  number
>("contracts/fetchSingleContract", api.contracts.getSingleContract);

export const fetchSingleContractWorkCompletedActs = createAsyncThunkInstance<
  ISingleContractWorkCompletedActsResponse,
  number
>(
  "contracts/fetchSingleContractWorkCompletedActs",
  api.contracts.getSingleContractWorkCompletedActs
);

export const fetchSingleContractAccessActs = createAsyncThunkInstance<
  IAccessActsResponse,
  number
>("contracts/fetchSingleContractAccessActs", api.contracts.getSingleContractAccessActs)

export const fetchSingleContractConstrictionRemarks = createAsyncThunkInstance<
  ISingleContractConstructionsResponse,
  number
>(
  "contracts/fetchSingleContractConstrictionRemarks",
  api.contracts.getSingleContractConstructionRemarks
);

export const fetchSingleContractPayments = createAsyncThunkInstance<
ISingleContractPaymentsResponse,
  number
>(
  "contracts/fetchSingleContractPayments",
  api.contracts.getSingleContractPayments
);

export const fetchSingleContractLettersGuarantees = createAsyncThunkInstance<
  ILetterGuaranteesResponse,
  number
>(
  "contracts/fetchSingleContractLettersGuarantees",
  api.contracts.getSingleContractLettersGuarantees
);

export const fetchSingleContractMaterials = createAsyncThunkInstance<
  IContractMaterialsResponse,
  number
>(
  "contracts/fetchSingleContractMaterials",
  api.contracts.getSingleContractMaterials
);

export const fetchLetterGuaranteePrintForm = createAsyncThunkInstance<
  IPrintFormResponse,
  string
>(
  "contracts/fetchLetterGuaranteePrintForm",
  api.contracts.getPrintFormLetterGuarantee
);

export const attachFilesToContract = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "contracts/attachFilesToContract",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(ContractsEndpoints.SINGLE_CONTRACT + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const writeFeedbackExecutor = createAsyncThunk<
  undefined,
  { id: number; files: File[]; message: string },
  { rejectValue: AxiosError }
>(
  "workCompletedActs/attachFilesToSingleWorkCompletedAct",
  async ({ id, files, message }, { rejectWithValue }) => {
    try {
      const { data } = await fetchFeedbackExecutor(id, files, message);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const handleImportMaterials = createAsyncThunk<
IContractMaterialsResponse,
  { limit_card_guid: string, contract_id: string, file: File, purchase_request: boolean },
  { rejectValue: AxiosError }
>(
  "contracts/handleImportMaterials",
  async ({ limit_card_guid, contract_id, file, purchase_request }, { rejectWithValue }) => {
    try {
      const { data } = await importMaterials(limit_card_guid, contract_id, file, purchase_request);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);


const contractsSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetCurrentContract(state) {
      state.currentContract = null;
    },
    changeCurrentContract(state, action) {
      state.currentContract = action.payload
    },
    resetCurrentContractSingleWorkCompletedAct(state) {
      state.currentContractSingleWorkCompletedAct = null;
    },
    setCurrentContractSingleWorkCompletedAct(state, action) {
      state.currentContractSingleWorkCompletedAct = action.payload
    },
    setCurrentLetterGuarantees(state, action) {
      state.currentLetterGuarantees = action.payload
    },
    resetLetterGuarantees(state) {
      state.currentLetterGuarantees = null
    },
    changeContractMaterials(state, action) {
      state.currentContractMaterials = action.payload
    },
    resetContractMaterials(state) {
      state.currentContractMaterials = []
    },
    setCurrentContractId(state, action) {
      state.currentContractId = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContracts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.contracts = action.payload.data;
      })

      .addCase(fetchArchiveContracts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchArchiveContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.archiveContracts = action.payload.data;
      })

      .addCase(fetchSingleContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleContract.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentContract = action.payload.data;
      })

      .addCase(fetchSingleContractWorkCompletedActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSingleContractWorkCompletedActs.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.currentContractWorkCompletedActs = action.payload.data;
        }
      )

      .addCase(fetchSingleContractAccessActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleContractAccessActs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentContractAccessActs = action.payload.data;
      })

      .addCase(fetchSingleContractConstrictionRemarks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSingleContractConstrictionRemarks.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.currentContractConstructionRemarks = action.payload.data;
        }
      )

      .addCase(fetchSingleContractPayments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleContractPayments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentContractPayments = action.payload.data;
      })

      .addCase(fetchSingleContractLettersGuarantees.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleContractLettersGuarantees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentContractLettersGuarantees = action.payload.data;
      })
      .addCase(fetchSingleContractMaterials.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleContractMaterials.fulfilled, (state, action) => {
        state.isLoading = false;
        const dataWithIds = action.payload.data.map((material: IContractMaterial) => ({
          ...material,
          table_guid: uuidv4(),
        }));
        state.currentContractMaterials = dataWithIds;
      })
      .addCase(handleImportMaterials.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(handleImportMaterials.fulfilled, (state, action) => {
        state.isLoading = false;
        const dataWithIds = action.payload.data.map((material: IContractMaterial) => ({
          ...material,
          table_guid: uuidv4(),
        }));
        state.currentContractMaterials = dataWithIds;
      })

      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const contractsReducer = contractsSlice.reducer;
export const { resetContractMaterials, changeContractMaterials, resetCurrentContract, resetCurrentContractSingleWorkCompletedAct, setCurrentContractSingleWorkCompletedAct, setCurrentLetterGuarantees, resetLetterGuarantees, changeCurrentContract,setCurrentContractId } = contractsSlice.actions;
