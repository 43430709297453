import { Center } from "@chakra-ui/react";

interface NoticeProps {
  number: number | undefined;
  overdueNotice?: number;
  isEmpty?: boolean
}

export function Notice(props: Readonly<NoticeProps>) {
  const {number, overdueNotice, isEmpty=false} = props;

  return (
    <Center
      w={overdueNotice ? 12 : 5}
      h={5}
      rounded="full"
      color="#ffffff"
      bg="#e8363d"
      fontSize="12px"
      lineHeight="12px"
      marginLeft={overdueNotice ? '-10px' : 0}
    >
      {overdueNotice ? (
        <span>{`${number} (${overdueNotice})`}</span>
      ) : (
        <span>{isEmpty ? "" : number}</span>
      )}
    </Center>
  );
}
