import {
  Box,
  ButtonGroup,
  Flex,
  FormControl,
  Grid,
  GridItem, Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Input as ChakraInput,
  ModalOverlay,
  Text,
  FormErrorMessage,
  useDisclosure,
  Menu,
  Tabs,
  TabPanels,
  TabPanel
} from "@chakra-ui/react";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { Comment } from "../../components/Comment/Comment";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  addSingleFenceCard, attachFilesToSingleFenceCard,
  resetCurrentLimitFenceCard,
} from "../../store/slices/limitFenceCards";
import { Input } from "../../components/lib/input/Input";
import { Loader } from "../../components/Loader/Loader";
import {
  CreateStockCardInformationBlock
} from "../../components/CreateStockCardComponents/CreateStockCardInformationBlock";
import { changeContractMaterials, fetchSingleContractMaterials, handleImportMaterials, resetContractMaterials, resetCurrentContract } from "../../store/slices/contracts";
import { ReactComponent as FileShare } from "../../images/svg/file/share.svg";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { StockCardPageBreadcrumbs } from "../../components/Breadcrumbs/StockCardPageBreadcrumbs";
import { CloseAlertModal } from "../../components/CloseAlertModal/CloseAlertModal";
import { IMaterialPivot, INewCreateLimitFenceCardRequest, INewLimitFenceCardMaterial, ISingleLimitFenceCardResponse } from "../../api/limitFenceCards/types";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { CreateMaterialsTable } from "../../components/SingleStockCardComponents/CreateMaterialsTable";
import { useFormik } from "formik";
import { IContractMaterial } from "../../api/contracts/types";
import { v4 as uuidv4 } from 'uuid';
import { ChakraModalProps } from "../../shared/types/modalProps";
import { formatCreateLzkRequest } from "../../helpers/formatCreateLzkRequest";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { OutlineMenuButton } from "../../components/lib/menu/ChakraMenuButton";
import { ChakraMenuList } from "../../components/lib/menu/ChakraMenuList";
import { exportMaterials } from "../../api/limitFenceCards";
import { downloadExportMaterials } from "../../helpers/downloadExportMaterials";
import { resetCurrentSupplier } from "../../store/slices/supplier";
import SuccessLogo from "../../images/png/success.png";
import ErrorLogo from "../../images/svg/warning/warning.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatAmount } from "../../helpers/formatAmount";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { singleLimitCardTabs } from "../../shared/mock/purchasePageData";

export interface IDivideMaterialModal extends ChakraModalProps {
  material?: IContractMaterial;
  rowIdx?: number;
  onDivideMaterialPeriod: () => void;
}

export function CreateStockCardPage({ dispatch, filesModal, setFilesModal, goBack }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [files, setFiles] = useState<File[]>([]);
  const [closeAlertModalOpen, setCloseAlertModalOpen] = useState<boolean>(false);
  const [material, setMaterial] = useState<IContractMaterial | undefined>(undefined);
  const [rowIdx, setRowIdx] = useState<number | undefined>(undefined);
  const { isOpen: isMaterialModalOpen, onOpen: onMaterialModalOpen, onClose: onMaterialModalClose } = useDisclosure();
  const { isOpen: isSuccessModalOpen, onClose: onSuccesModalClose, onOpen: onSuccesModalOpen } = useDisclosure();
  const { isOpen: isErrorModalOpen, onClose: onErrorModalClose, onOpen: onErrorModalOpen } = useDisclosure();
  const [waitingModal, setWaitingModal] = useState<boolean>(false)
  const [completedMaterials, setCompletedMaterials] = useState<INewLimitFenceCardMaterial[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createdGuid, setCreatedGuid] = useState<string | null>(null)
  const [summ, setSumm] = useState<string>("")
  const tabIndex = Number(searchParams.get("tab"));

  const setDefaultTabParam = (index: number): void => {
    index === 0
      ? searchParams.delete("tab")
      : searchParams.set("tab", String(index));

    setSearchParams(searchParams);
  };

  const navigate = useNavigate();

  const { currentLimitFenceCard } = useAppSelector(
    (state: RootState) => state.limitFenceCards
  );
  const { currentContract, currentContractMaterials } = useAppSelector(
    (state: RootState) => state.contracts
  );

  const { currentSupplier } = useAppSelector(
    (state: RootState) => state.supplier
  );

  const [dataState, setDataState] = useState({
    contract_id: currentContract?.id.toString() ?? "",
    period_from: "",
    period_to: "",
    comment: "",
    create_rp: false
  });

  const [isRunSpinner, setIsRunSpinner] = useState(false);

  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      contract_id: "",
      period_from: "",
      period_to: "",
      comment: "",
      supplier_guid: "",
      supplier_inn: "",
      supplier_name: "",
      supplier_kpp: "",
      supplier_account: "",
      supplier_account_date_from: "",
      create_rp: false,
      purchase_request: false,
      materials: []
    },
    onSubmit: () => {},
  });


  const onChangeData = useCallback((
    period_from_value: string,
    period_to_value: string,
    contract_id_value: string,
    create_rp: boolean,
  ) => {
    setDataState((dataState) => ({
      ...dataState,
      period_from: period_from_value,
      period_to: period_to_value,
      contract_id: contract_id_value,
      create_rp: create_rp
    }));
  }, []);

  const fileAttach = (id: number) => {
    dispatch(attachFilesToSingleFenceCard({ id: id, files: files }))
      .then(() => goBack())
      .catch((e: Error) => {
        console.error('Error: ', e);
      })
      .finally(() => {
        setIsRunSpinner(false);
      });
  }

  const onSubmit = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    let isError: boolean = false;
    const updatedMaterials = currentContractMaterials.map((el: IContractMaterial) => {
      if ((el.isDivide && (el.period === "" || !el.period)) ||
          (!!el.period && (formik.values.period_from.split(" ")[0] > el.period || formik.values.period_to.split(" ")[0] < el.period))) {
        isError = true;
        return {...el, isErrorPeriod: true}
      } else {
        return el;
      }
    })
    if (isError) {
      dispatch(changeContractMaterials(updatedMaterials))
      onErrorModalOpen()
    } else {
      setTimeout(() => {
        const data = formatCreateLzkRequest(formik.values, currentContractMaterials)
        setWaitingModal(true)
        dispatch(addSingleFenceCard(data))
          .then((res: any) => {
            if (res.payload.success) {
              setCreatedGuid(res.payload.data.id)
              onSuccesModalOpen();
            }
          })
          .finally(() => {
            setWaitingModal(false)
          })
      }, 2000)

    }
  };

  const handleChangeComment = useCallback((comment_value: string) => {
    setFieldValue("comment", comment_value)
  }, []);


  const addItemAsLine = () => {
    const newArray = Object.assign([], currentContractMaterials);
    newArray.unshift({
      "material_guid": "",
      "ssom_id": "",
      "ssom_order_number": "",
      "material_name": "",
      "analog_material": "",
      "unit_of_measurement": "",
      "indicative_price": "",
      "tender_quantity": "",
      "tender_price": "",
      "tender_solution": "",
      "inventory_supervisor": "",
      "custom_unit_of_measurement": "",
      "custom_material_name": "",
      "isNewItem": true,
      "table_guid": uuidv4()
    })

    dispatch(changeContractMaterials(newArray))
  }

  const onBackBtnClick = () => {
    if (files.length) {
      setCloseAlertModalOpen(true);
    } else {
      goBack();
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCurrentLimitFenceCard());
      dispatch(resetCurrentContract());
      dispatch(resetCurrentSupplier());
      dispatch(resetContractMaterials());
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentContract) {
      setFieldValue("contract_id", currentContract.id.toString())
    }
  }, [currentContract, dispatch, setFieldValue]);
  
  const setMaterialRow = (material: IContractMaterial | undefined, idx: number | undefined): void => {
    setMaterial(material);
    setRowIdx(idx);
  }

  const onDivideMaterialPeriod = () => {
    setMaterialRow(undefined, undefined);
    setDisabled(false);
  }

  useEffect(() => {
    setFieldValue("supplier_guid", currentSupplier?.guid)
  }, [currentSupplier, setFieldValue])

  useEffect(() => {
    setFieldValue("materials", completedMaterials)
  }, [completedMaterials, setFieldValue])

  useEffect(() => {
    !currentContract &&  dispatch(resetContractMaterials())
  }, [currentContract, dispatch])

  useEffect(() => {
    if (currentContract) {
      setWaitingModal(true)
      dispatch(fetchSingleContractMaterials(currentContract.id))
      .finally(() => setWaitingModal(false))
    }
  }, [])

  const keepFields = ['material_guid']; // Поля, которые нужно оставить

  const filteredArray = currentContractMaterials.filter((obj: any) => (obj.amount && obj.amount !== "" && obj.amount !== 0 && obj.amount !== "0.000") || obj.isNewItem)
    .map((obj: any) => {
      const newObj: any = {};
      keepFields.forEach((field) => {
        newObj[field] = obj[field];
      });
      newObj.guid = obj.guid ?? uuidv4();
      newObj.amount = Number(obj.amount);
      newObj.custom_material_name = obj.custom_material_name ? obj.custom_material_name : "";
      newObj.custom_unit_of_measurement = obj.custom_unit_of_measurement ? obj.custom_unit_of_measurement : "";
      newObj.tender_price = obj.tender_price && Number(obj.tender_price);
      newObj.comment = obj.comment ? obj.comment : "";
      newObj.period = obj.period ? obj.period : "";
      return newObj;
    });

  const handleExport = () => {
    downloadExportMaterials({
      limit_card_guid: "",
      contract_id: currentContract ? currentContract?.id : null,
      materials: filteredArray,
      purchase_request: formik.values.purchase_request,
      supplier_name: formik.values.purchase_request ? formik.values.supplier_name : "",
    }, setWaitingModal)
  }

  const handleImportClick = () => {
    fileInputRef.current?.click();
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (currentContract && file) {
      setWaitingModal(true)
      dispatch(handleImportMaterials({ limit_card_guid: "", contract_id: String(currentContract.id), file, purchase_request: formik.values.purchase_request }))
        .finally(() => {
          setWaitingModal(false)
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        })
    }
  };

  const formatCompletedMaterials = useMemo(() => {
    return (array: any) => {
      return array.filter((material: INewLimitFenceCardMaterial) => {
        return (Number(material.amount) > 0);
      });
    };
  }, []);

  useEffect(() => {
    if (currentContractMaterials.length > 0) {
      setCompletedMaterials(formatCompletedMaterials(currentContractMaterials))
    }
  }, [currentContractMaterials, formatCompletedMaterials])

  useEffect(() => {
    if (!formik.values.purchase_request && currentContractMaterials.length > 0) {
      const filteredMaterials = currentContractMaterials.filter(item => !item.isNewItem);
      dispatch(changeContractMaterials(filteredMaterials))
    }
  }, [formik.values.purchase_request])

  const calculateTotalSum = (data: any) => {
    return data.reduce((sum: any, item: any) => {
      const amount = Number(item.amount);
      const tenderPrice = Number(item.tender_price);
      const product = isNaN(amount) || isNaN(tenderPrice) ? 0 : amount * tenderPrice; //Обработка NaN
      return sum + product;
    }, 0);
  };

  useEffect(() => {
    if (currentContractMaterials) {
      const totalSum = calculateTotalSum(currentContractMaterials);
      setSumm(formatAmount(totalSum))
    }
  }, [currentContractMaterials])

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <StockCardPageBreadcrumbs currentPageText="Создать лимитно-заборную карту" />
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={onBackBtnClick} />
              <BlockTitle>Создать лимитно-заборную карту</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <OutlineButton isDisabled>Согласовать</OutlineButton>
              <OutlineButton isDisabled>Создать отражение факта поставки</OutlineButton>
              <OutlineButton isDisabled>Перезапустить</OutlineButton>
              <PrimaryButton isDisabled={disabled} onClick={onSubmit}>
                Записать
              </PrimaryButton>
              <OutlineButton leftIcon={<Icon as={FileShare} />} onClick={() => setFilesModal(true)}>Файлы</OutlineButton>
            </ButtonGroup>
          </Flex>
          <Tabs defaultIndex={tabIndex} onChange={setDefaultTabParam}>
            <ChakraTabs tabs={singleLimitCardTabs} />
            <TabPanels>
              <TabPanel px={0} pb={0}>
                <Grid templateColumns="1fr 504px" gap={4}>
                  <GridItem>
                    <CreateStockCardInformationBlock setDisabled={setDisabled} formik={formik} setFieldValue={setFieldValue} />
                  </GridItem>
                  <GridItem>
                    <Comment currentComment={""} handleChangeComment={handleChangeComment} isEdited />
                  </GridItem>
                </Grid>
              </TabPanel>
              <TabPanel px={0} pb={0}>
                <Flex pt={6} gap={4} w="full" direction="column">
                  <Flex justify="space-between">
                    <Flex gap={6} align="center" maxW="458px">
                      <BlockTitle>Материалы</BlockTitle>
                      <Input
                        placeholder="Поиск"
                        id="search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        search
                      />
                    </Flex>
                    <Flex flexDirection="column" gap="8px">
                      <Text alignSelf="end" size="40px" textAlign="end" fontWeight={500}>{`ИТОГО: ${summ}`}</Text>
                      <ButtonGroup spacing={3}>
                        <Menu>
                          <OutlineMenuButton rightIcon={<ChevronDownIcon />}>
                            Excel
                          </OutlineMenuButton>
                          <ChakraMenuList
                            list={[
                              {
                                title: "Загрузить из файла",
                                onClick: () => handleImportClick()
                              },
                              {
                                title: "Выгрузить в файл",
                                onClick: () => handleExport(),
                                isDisabled: formik.values.purchase_request && !formik.values.supplier_name
                              },
                            ]}
                          />
                        </Menu>
                        {formik.values.purchase_request && (
                          <>
                            <PrimaryButton onClick={addItemAsLine}>Добавить номенклатуру строкой</PrimaryButton>
                          </>

                        )
                        }
                        <PrimaryButton isDisabled={!material || Number(material.amount) === 0 || !material.amount || !material.period || material.period === ""} onClick={onMaterialModalOpen}>Разбить период</PrimaryButton>
                      </ButtonGroup>
                    </Flex>
                  </Flex>
                  <CreateMaterialsTable searchValue={searchValue} purchaseRequest={formik.values.purchase_request} setMaterialRow={setMaterialRow} />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>
        <input type="file" accept=".xls,.xlsx" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      </Flex>
      {isRunSpinner && <Loader isLoading={isRunSpinner} />}
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        setFilesOnCreate={setFiles}
      />
      <FullScreenWaitingModal openModal={waitingModal} />
      <CloseAlertModal isOpen={closeAlertModalOpen} onClose={() => setCloseAlertModalOpen(false)} closeDrawer={goBack} />
      <DivideMaterialModal isOpen={isMaterialModalOpen} onClose={onMaterialModalClose} material={material} onDivideMaterialPeriod={onDivideMaterialPeriod} />
      <SuccessModal createdGuid={createdGuid} isOpen={isSuccessModalOpen} onClose={() => {
        onSuccesModalClose()
        if (createdGuid !== null) {
          navigate(`/purchase/stock-cards/${createdGuid}`)
        } else {
          navigate("/purchase/stock-cards")
        }
        }}/>
        <ErrorModal isOpen={isErrorModalOpen} onClose={onErrorModalClose} />
    </MainLayout>
  );
}

function DivideMaterialModal({onClose, isOpen, material, onDivideMaterialPeriod}: IDivideMaterialModal) {
  const dispatch = useAppDispatch();
  const { currentContractMaterials } = useAppSelector((state) => state.contracts);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  }

  const onSubmit = (values: IMaterialPivot) => {
    const updatedMaterials: IContractMaterial[] = [];
    let inserted = false;

    currentContractMaterials?.forEach((existingMaterial, index) => {
      if (existingMaterial.table_guid === material?.table_guid) {
        updatedMaterials.push({ ...existingMaterial, amount: String(Number(existingMaterial.amount) - Number(values.amount)), guid: uuidv4(), isDivide: true });
        inserted = true;

        material && updatedMaterials.push({ ...material, amount: values.amount, table_guid: uuidv4(), period: values.period, isDivide: true});
      } else {
        updatedMaterials.push(existingMaterial);
      }
    });

    dispatch(changeContractMaterials(updatedMaterials));
    onDivideMaterialPeriod();
    handleClose();
  };

  const {setFieldValue, ...formik} = useFormik({
    initialValues: {
      order_number: 0,
      limit_card_id: 0,
      material_id: "",
      amount: "",
      period: "",
      comment: "",
      guid: ""
    },
    onSubmit,
  });

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let inputValue: string = e.target.value;
    const reg = /(?<=\.\d{3}|,\d{3})\d+/g;
    if (reg.test(e.target.value)) {
      inputValue = e.target.value.replace(reg, "");
    }
    setFieldValue('amount', inputValue);
  }

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let inputValue: string = e.target.value;
    setFieldValue('period', inputValue);
  }

  useEffect(() => {
    setFieldValue("material_id", material ? material?.material_guid : 0);
    setFieldValue("guid", material ? material?.guid : 0);
    setFieldValue("period", material ? material?.period : "");
  }, [material, setFieldValue]);

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay backdropFilter="blur(3px)"/>
      <ModalContent maxW="360px" gap="24px" px="24px" paddingTop="32px" paddingBottom="24px">
        <ModalHeader p={0}>
          <Flex flexDirection="column" gap="12px" align="center">
            <Text
              fontSize="24px"
              color="#343b4c"
              lineHeight="28px"
              letterSpacing="-1.5%"
              fontWeight={500}
              align="center">
              Разбить период
            </Text>
            <Text fontSize={16} lineHeight="20px" fontWeight={400} color="#5C657E" align="center">Вы должны указать количество меньше, чем в разбиваемом периоде, а также заполнить дату</Text>
            <Text fontSize={16} lineHeight="20px" fontWeight={500} color="#5C657E" align="center">Текущее количество: {material?.amount}</Text>
          </Flex>
        </ModalHeader>
        <ModalBody p={0}>
          <FormControl>
            <Flex flexDirection="column" gap="12px">
              <ChakraInput
                id="amount"
                value={formik.values.amount}
                onChange={handleAmountChange}
                placeholder="Укажите количество"
                borderColor="#E3E7EF"
                type="number"
                isInvalid={!!material?.amount && Number(formik.values.amount) >= Number(material?.amount)}
              />
              <ChakraInput
                id="period"
                value={formik.values.period}
                onChange={handlePeriodChange}
                borderColor="#E3E7EF"
                type="date"
                isInvalid={formik.values.period === ""}
              />
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter p={0}>
          <Flex w="100%" gap="8px" align="center">
            <OutlineButton w="full" onClick={handleClose}>
              Отмена
            </OutlineButton>
            <PrimaryButton w="full" onClick={() => onSubmit(formik.values)} isDisabled={!formik.values.amount || Number(formik.values.amount) >= Number(material?.amount) || formik.values.period === ""}>
              Разбить
            </PrimaryButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface SuccessModalProps extends ChakraModalProps {
  createdGuid: string | null
}

const SuccessModal = ({ onClose, isOpen, createdGuid }: SuccessModalProps) => {
  const navigate = useNavigate()
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
          <img width="64px" src={SuccessLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
              Лимитно-заборная карта успешно создана
            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={() => {
            onClose();
            if (createdGuid !== null) {
              navigate(`/purchase/stock-cards/${createdGuid}`)
            } else {
              navigate("/purchase/stock-cards")
            }
          }}>
            Закрыть
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ErrorModal = ({ onClose, isOpen }: ChakraModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300} w={500} maxW={"fit-content"}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
          <img width="64px" src={ErrorLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
              У одного или нескольких материалов, для которого выполнялось разбиение периода, не заполнена дата, а также указаны даты, не соответствующие периоду поставки            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={onClose}>
            Ок
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
