import { Flex, Text } from "@chakra-ui/react"
import { CellContext } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import { ISupplier } from "../../api/marketplace/types"

export const TitleCell = ({ row: { original } }: CellContext<ISupplier, string>) => {
    const navigate = useNavigate();
    return (
        <Flex align="center" justify="space-between" onClick={() => {
            original.supplier_order_guid && navigate(`customer/${original.supplier_order_guid}`)
        }}>
            <Text color={original.supplier_order_guid ? "#9A1217" : "#1F222A"} fontSize="16px" lineHeight="20px" borderBottom={original.supplier_order_guid ? "1px solid #9A1217" : "none"}>{original.name}</Text>
        </Flex>
    );
}; 