import { Flex, Menu, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { PrimaryButton } from "../lib/button/Button";
import { ChakraMenuList } from "../lib/menu/ChakraMenuList";
import { BlockTitle } from "../lib/title/Title";
import { ActsTable } from "./ActsTable";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { OutlineMenuButton } from "../lib/menu/ChakraMenuButton";
import { changeEstimate, fetchSingleEstimate, fetchSingleEstimateFromAct } from "../../store/slices/estimates";
import { estimateDetailsWithQuantity } from "../../helpers/estimateDetailsWithQuantity";
import { useLocation, useNavigate } from "react-router-dom";
import { ISingleEstimateResponse } from "../../api/estimates/types";
import { deleteSingleDraftAct, fetchPrintFormWorkAct, resetCurrenWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { useState } from "react";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { checkPermission } from "../../helpers/checkPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { fetchSingleContractWorkCompletedActs } from "../../store/slices/contracts";
import { ConfirmDeleteModal } from "../Modal/ModalCenter/ConfirmDelete";


export function ActsBlock() {
  const { currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const { downloadFile } = useUniversalDownloadFile();
  const [isSpinnerRuning, setIsSpinnerRuning] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isRegistered = () => {return currentContract?.status == "Зарегистрирован"}

  const requestPrintForm = (type: string) => {
    downloadFile(currentWorkCompletedAct?.id, setIsSpinnerRuning, fetchPrintFormWorkAct, {type: type})
  }
  const actMenuList: { title: string, onClick?: () => void, isDisabled?: boolean }[] = [
    {
      title: "КС-2",
      onClick: () => requestPrintForm("ks_2"),
      isDisabled: !isRegistered()
    },
    {
      title: "Счёт",
      onClick: () => requestPrintForm("payment"),
      isDisabled: !isRegistered()
    },
    {
      title: "Счёт-фактуры",
      onClick: () => requestPrintForm("check"),
      isDisabled: !isRegistered()
    },
    {
      title: "Отгрузочные документы",
      onClick: () => requestPrintForm("shipping_documents"),
      isDisabled: !isRegistered()
    },
    {
      title: "Накопительная ведомость",
      onClick: () => requestPrintForm("accumulative_statement"),
      isDisabled: !isRegistered()
    },
    {
      title: "КС-3",
      onClick: () => requestPrintForm("ks_3"),
      isDisabled: !isRegistered()
    }
  ];
  const { currentWorkCompletedAct } = useAppSelector(
    (state) => state.workCompletedActs
  );
  const { currentEstimate } = useAppSelector((state) => state.estimates);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();

  function sumTheAmountForParents(rows: any): any[] {
    function calculateSum(rows: any): any[] {
      return rows.map((row: any) => {
        let sum = Number(row.Thenumberofstops) === 0 && Number(row.AmountRemaining) < 0.5 ? Number(row.AmountRemaining) :  Number(row.Theamount);
        if (row.subRows && row.subRows.length > 0) {
          const updatedSubRows = calculateSum(row.subRows);
          sum += updatedSubRows.reduce((acc: number, cur: any) => {
            if (Number(cur.Thenumberofstops) === 0 && Number(cur.AmountRemaining) < 0.5) {
              return acc + Number(cur.AmountRemaining)
            } else {
              return acc + Number(cur.Theamount)
            }
          }, 0);
          return { ...row, subRows: updatedSubRows, Theamount: (sum - Number(row.AmountRemaining)) < 0.5 ? sum.toFixed(10) : row.AmountRemaining };
        }
        return { ...row, Theamount: (sum - Number(row.AmountRemaining)) < 0.5 ? sum.toFixed(10) : row.AmountRemaining}
      });
    }
    return calculateSum(rows);
  }

  function restructureEstimates(estimates: any[]): any[] {
    function deepClone(obj: any): any {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map(deepClone);
      }

      const clonedObj: any = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          clonedObj[key] = deepClone(obj[key]);
        }
      }
      return clonedObj;
    }

    function processEstimate(estimate: any): any {
      const newEstimate = deepClone(estimate);

      if (newEstimate.materials && Array.isArray(newEstimate.materials) && newEstimate.materials.length > 0) { // Проверяем materials
        newEstimate.subRows = newEstimate.subRows || []; // Используем оператор || для инициализации
        newEstimate.materials.forEach((material: any) => {
          const newMaterial = deepClone(material);
          const updatedMaterial = { ...newMaterial, isMaterial: true };
          newMaterial.subRows = [];
          newEstimate.subRows.push(updatedMaterial);
        });
        newEstimate.hasMaterials = true;
        delete newEstimate.materials;
      } else {
        newEstimate.hasMaterials = false; // Если materials нет, ставим hasMaterials в false
      }

      if (newEstimate.subRows) {
        newEstimate.subRows = newEstimate.subRows.map(processEstimate);
      }

      return newEstimate;
    }

    return estimates.map(processEstimate);
  }

  const handleCreateClick = async () => {
    currentWorkCompletedAct && dispatch(fetchSingleEstimateFromAct(currentWorkCompletedAct.id))
      .then((res:any) => {
        const payload = res.payload as ISingleEstimateResponse
        const total = sumTheAmountForParents(res.payload.data.details);
        const restructuredEstimates = restructureEstimates(total)
        const updatedEstimate = { ...payload.data, details: restructuredEstimates }
        dispatch(changeEstimate(updatedEstimate))
        navigate(`${pathname}/create-act-based-act`)
      })
  }

  const isDisabledByAct = () => {return !currentWorkCompletedAct || (currentWorkCompletedAct.status !== "Отклонена" && currentWorkCompletedAct.status !== "Черновик ЛКП"); }
  return (
    <Flex direction="column" gap={4} maxH="full" h="full">
      <BlockTitle>Акты</BlockTitle>
      <Flex gap="12px" >
        <PrimaryButton isDisabled={isDisabledByAct() || currentContract?.is_archived || !checkPermission(AccessRights.WorkCompletedAct_RW, permissions)} onClick={handleCreateClick}>Создать на основании</PrimaryButton>
        <Menu>
          <OutlineMenuButton
            leftIcon={<DownloadIcon />}
            rightIcon={<ChevronDownIcon />}
            isDisabled={!currentWorkCompletedAct || currentWorkCompletedAct?.status === "Черновик ЛКП"}
          >
            Скачать
          </OutlineMenuButton>
          <ChakraMenuList list={actMenuList} />
        </Menu>
        <PrimaryButton isDisabled={!checkPermission(AccessRights.WorkCompletedAct_RO, permissions) || currentWorkCompletedAct?.status !== "Черновик ЛКП"} onClick={() => onOpen()}>Удалить</PrimaryButton>
      </Flex>
      <ActsTable />
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
      <ConfirmDeleteModal
        isOpen={isOpen}
        onClose={onClose}
        mainText="акт выполненных работ"
        deleteId={currentWorkCompletedAct?.id ?? ""}
        deleteFunction={deleteSingleDraftAct}
        updateFunction={fetchSingleContractWorkCompletedActs}
        updateId={currentContract?.id ?? ""}
        resetFunction={resetCurrenWorkCompletedAct}
      />
    </Flex>
  );
}
