import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  Menu,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { addWorkCompletedAct, attachFilesToSingleWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import {
  OutlineMenuButton,
  PrimaryMenuButton,
} from "../../components/lib/menu/ChakraMenuButton";
import {
  ChakraMenuItem,
  ChakraMenuList,
} from "../../components/lib/menu/ChakraMenuList";
import { BlockTitle } from "../../components/lib/title/Title";
import { MainTableBlock } from "../../components/CreateActBasedOnEstimateComponents/MainTableBlock/MainTableBlock";
import { CreateActInformationBlock } from "../../components/CreateActBasedOnEstimateComponents/CreateActInformationBlock";
import { BuildingObjectsBlock } from "../../components/CreateActBasedOnEstimateComponents/BuildingObjectsBlock/BuildingObjectsBlock";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ICreateWorkCompletedActRequest, ICreateWorkCompletedActResponse } from "../../api/workCompletedActs/types";
import styles from "./create-act.module.scss";
import { Loader } from "../../components/Loader/Loader";
import { CloseFormModal } from "../../components/CloseFormModal/CloseFormModal";
import { SingleEstimateActPrintData } from "../../components/SingleEstimateActPrintData/SingleEstimateActPrintData";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { russianPatternCheck } from "../../helpers/russianPatternCheck";
import { downloadExportEstimate } from "../../helpers/downloadExportEstimate";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { changeEstimate, fetchGetImportEstimate, fetchVerifyWorks, handleImportEstimate, resetCurrentImportEstimate, setCurrentDetails } from "../../store/slices/estimates";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { IImportEstimateResponse, IVerifyWorkDetailRequest } from "../../api/estimates/types";
import { ConfirmImportModal } from "../../components/CreateActBasedOnEstimateComponents/ConfirmImportModal";
import { WorkActErrorModal } from "../../components/Modal/ModalCenter/WorkActErrorModal";
import SuccessLogo from "../../images/png/success.png";
import { ChakraModalProps } from "../../shared/types/modalProps";
import { checkAndSetIsErrorRecursive } from "../../helpers/checkAndSetIsError";
import { AnyArray } from "immer/dist/internal";

enum ActStatuses {
  WorkingDocument = "Рабочая",
  DraftDocument = "Черновик ЛКП"
}

interface HeaderButtonGroupProps {
  activePage: number;
  changePage: () => void;
  onSubmit: (status: ActStatuses) => void;
  isLoading: boolean;
  closeLoading: (arg: boolean) => void;
  fieldsAreSet: () => boolean;
  onOpen: () => void;
  handleOpenFilesModal: () => void;
  handleExport: () => void;
  handleImport: () => void;
  formik: FormikProps<ICreateWorkCompletedActRequest>;
  isInvalid: boolean;
  setCurrentStatus: (arg: ActStatuses) => void;
  setIsDraftCreate: (arg: boolean) => void
}

export function CreateActBasedOnEstimatePage({ filesModal, setFilesModal, dispatch, goBack }: any) {
  const navigate = useNavigate();
  const { currentEstimate, currentDetails, errorEstimates } = useAppSelector((state) => state.estimates);
  const detailsWithQuantity = currentDetails.filter(detail => Number(detail.quantityinthedocument) !== 0);
  const { currentContract } = useAppSelector((state) => state.contracts)
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { currentVat } = useAppSelector((state) => state.vats);
  const [currentStatus, setCurrentStatus] = useState<ActStatuses | null>(null);
  const [createdGuid, setCreatedGuid] = useState<string | null>(null)
  const [isDraftCreate, setIsDraftCreate] = useState<boolean>(false)

  const [activePage, setActivePage] = useState(1);
  const [isRunSpinner, setIsRunSpinner] = useState(false);
  const [exportSpinnerRun, setExportSpinnerRun] = useState<boolean>(false)
  const [files, setFiles] = useState<File[]>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false)

  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isConfirmModalOpen, onClose: isConfirmModalClose, onOpen: onConfirmModalOpen } = useDisclosure();
  const { isOpen: isErrorModalOpen, onClose: onErrorModalClose, onOpen: onErrorModalOpen } = useDisclosure();
  const { isOpen: isSuccessModalOpen, onClose: onSuccesModalClose, onOpen: onSuccesModalOpen } = useDisclosure();

  const handleChangePage = () => {
    if (activePage === 1) {
      if (detailsWithQuantity.length > 0) {
        setExportSpinnerRun(true)
        const resultDetailsArray: IVerifyWorkDetailRequest[] = detailsWithQuantity.map((detail) => ({
          ConstructionObjectID: detail.ConstructionObjectID,
          TypeofWork: detail.TypeofWorkID,
          quantity: Number(detail.quantityinthedocument),
          Price: Number(detail.Price),
          Total: Number(detail.Theamount)
        }))
        currentEstimate && dispatch(fetchVerifyWorks({ id: currentEstimate?.guid, details: resultDetailsArray }))
          .then((res: {
            payload: {
              data: any;
            };
          }) => {
            if (res.payload.data.length === 0) {
              setActivePage(2);
            } else {
              const resultDetails = checkAndSetIsErrorRecursive(currentEstimate.details, res.payload.data)
              const updatedEstimate = { ...currentEstimate, details: resultDetails };
              dispatch(changeEstimate(updatedEstimate))
              onErrorModalOpen()
            }
          })
          .finally(() => setExportSpinnerRun(false))
      } else {
        setActivePage(2)
      }
    } else {
      setActivePage(1)
    }
  };

  const handleOpenFilesModal = () => {
    setFilesModal(true);
  }

  const fileAttach = (id: string) => {
    dispatch(attachFilesToSingleWorkCompletedAct({ id: id, files: files }))
      .catch((e: Error) => {
        console.error("Error: ", e);
      });
  };

  useEffect(() => {
    if (!currentEstimate) {
      const remove = "/create-act"
      goBack(location.pathname.slice(0, -remove.length))
    }
  }, [currentEstimate])

  const formik: FormikProps<ICreateWorkCompletedActRequest> =
    useFormik<ICreateWorkCompletedActRequest>({
      initialValues: {
        invoice_number: "",
        invoice_date: "",
        act_number: "",
        act_date: "",
        sf_number: "",
        sf_date: "",
        manager_name: "",
        manager_name_power_of_attorney: "",
        chief_accountant: "",
        chief_accountant_power_of_attorney: "",
        date_from: "",
        date_to: "",
        status: "",
        contract_id: currentContract?.id ?? 0,
        estimate_id: currentEstimate?.id ?? 0,
        details: detailsWithQuantity.map((detail) => ({
          ConstructionObjectID: detail.ConstructionObjectID,
          TypeofWork: detail.TypeofWork,
          quantity: Number(detail.quantityinthedocument),
          Price: Number(detail.Price),
          Total: Number(detail.Theamount),
        })),
        advance_payments: [],
        executive_documentation: "",
        vat: currentVat ?? "",
      },
      validationSchema: Yup.object({
        invoice_number: Yup.string().required(),
        invoice_date: Yup.string().required(),
        act_number: Yup.string().required(),
        act_date: Yup.string().required(),
        sf_number: Yup.string().required(),
        sf_date: Yup.string().required(),
        manager_name: Yup.string().required(),
        manager_name_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
            Yup.string().required() : Yup.number().positive().nullable()
        ),
        chief_accountant: Yup.string().required(),
        chief_accountant_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
            Yup.string().required() : Yup.number().positive().nullable()
        ),
        date_from: Yup.string().required(),
        date_to: Yup.string().required(),
        status: Yup.string().required(),
        contract_id: Yup.number().required(),
        estimate_id: Yup.number().required(),
        details: Yup.array().required(),
      }),
      onSubmit: () => dispatch(addWorkCompletedAct(formik.values)),
      enableReinitialize: false,
      validateOnBlur: true,
      validateOnMount: true,
    });


  useEffect(() => {
    formik.setFieldValue("vat", currentVat)
  }, [currentVat])


  useEffect(() => {
    formik.values.details = detailsWithQuantity.map((detail) => ({
      ConstructionObjectID: detail.ConstructionObjectID,
      TypeofWork: detail.TypeofWork,
      quantity: Number(detail.quantityinthedocument),
      Price: Number(detail.Price),
      Total: Number(detail.Theamount,),
      vat: detail.vat
    }))
  }, [detailsWithQuantity, currentDetails])

  const fieldsAreSet = () => {
    const res = formik.values.invoice_number.trim() !== '' && formik.values.invoice_date !== '' &&
      formik.values.act_number.trim() !== '' && formik.values.act_date !== '' &&
      formik.values.sf_number.trim() !== '' && formik.values.sf_date !== '' &&
      formik.values.manager_name.trim() !== '' && formik.values.chief_accountant.trim() !== '' &&
      formik.values.date_from !== '' && formik.values.date_to !== '' &&
      russianPatternCheck(formik.values.manager_name) && russianPatternCheck(formik.values.chief_accountant);

    return res;

  }

  useEffect(() => {
    const requiredFieldsFilled =
      formik.values.invoice_number.trim() !== '' &&
      formik.values.invoice_date !== '' &&
      formik.values.act_number.trim() !== '' &&
      formik.values.act_date !== '' &&
      formik.values.sf_number.trim() !== '' &&
      formik.values.sf_date !== '' &&
      formik.values.manager_name.trim() !== '' &&
      formik.values.chief_accountant.trim() !== '' &&
      formik.values.date_from !== '' &&
      formik.values.date_to !== '' &&
      russianPatternCheck(formik.values.manager_name) &&
      russianPatternCheck(formik.values.chief_accountant) &&
      formik.values.executive_documentation !== "";


    const advancePaymentsFilled = formik.values.advance_payments ?
      formik.values.advance_payments.every(item => (item.date !== "" && item.name !== "") || (item.date === "" && item.name === "")) :
      true;

    setIsInvalid(requiredFieldsFilled && advancePaymentsFilled);
  }, [formik.values])

  const onSubmit = (status: ActStatuses, acceptCreate?: boolean) => {
    if (currentContract && currentEstimate) {
      if (errorEstimates.length > 0 && !acceptCreate) {
        onErrorModalOpen();
      } else {
        setIsRunSpinner(true);
        dispatch(
          addWorkCompletedAct({
            ...formik.values,
            status,
          })
        )
          .then((res: { payload: ICreateWorkCompletedActResponse; }) => {
            if (!!files.length) {
              fileAttach((res.payload as ICreateWorkCompletedActResponse)?.data.id);
            }
            if (isDraftCreate) {
              setCreatedGuid(`${res.payload.data.id}/draft`)
            } else {
              setCreatedGuid(`${res.payload.data.id}`)
            }
            onSuccesModalOpen()
          })
          .catch((e: Error) => {
            console.error('Error: ', e);
          })
          .finally(() => {
            setIsRunSpinner(false);
          })
      }
    }
  };

  const handleExportClick = () => {
    currentEstimate && downloadExportEstimate(currentEstimate.id, detailsWithQuantity.map((detail) => ({
      id: detail.id,
      quantity: Number(detail.quantityinthedocument),
      Price: Number(detail.Price),
      Total: Number(detail.Theamount),
    })), setExportSpinnerRun)
  }


  function sumTheAmountForParents(rows: any): any[] {
    function calculateSum(rows: any): any[] {
      return rows.map((row: any) => {
        if (Number(row.quantityinthedocument) > Number(row.Thenumberofstops)) {
          const price = Number(row.Price);
          const correctedQuantity = Number(row.Thenumberofstops);
          row.quantityinthedocument = correctedQuantity.toFixed(3);
          row.Theamount = (price * correctedQuantity).toFixed(3);
        }

        let sum = Number(row.Theamount);

        if (row.subRows && row.subRows.length > 0) {
          const updatedSubRows = calculateSum(row.subRows);
          sum += updatedSubRows.reduce((acc: number, cur: any) => acc + Number(cur.Theamount), 0);
          return { ...row, subRows: updatedSubRows, Theamount: sum.toFixed(3) };
        }
        return { ...row, Theamount: sum.toFixed(3) };
      });
    }
    return calculateSum(rows);
}

  function getNonEmptyQuantityItems(items: any) {
    let result: any = [];

    function traverse(nodes: AnyArray) {
      if (nodes !== undefined) {
        for (const node of nodes) {
          // Проверяем текущий узел
          if (node && node.quantityinthedocument !== "0.000" && node.quantityinthedocument !== "") {
            result.push(node);
          }

          if (node && node.subRows && Array.isArray(node.subRows)) {
            traverse(node.subRows);
          }
        }
      }
    }

    traverse(items);
    return result;
  }

  function restructureEstimates(estimates: any[]): any[] {
    function deepClone(obj: any): any {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map(deepClone);
      }

      const clonedObj: any = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          clonedObj[key] = deepClone(obj[key]);
        }
      }
      return clonedObj;
    }

    function processEstimate(estimate: any): any {
      const newEstimate = deepClone(estimate);

      if (newEstimate.materials && Array.isArray(newEstimate.materials) && newEstimate.materials.length > 0) { // Проверяем materials
        newEstimate.subRows = newEstimate.subRows || []; // Используем оператор || для инициализации
        newEstimate.materials.forEach((material: any) => {
          const newMaterial = deepClone(material);
          const updatedMaterial = { ...newMaterial, isMaterial: true };
          newMaterial.subRows = [];
          newEstimate.subRows.push(updatedMaterial);
        });
        newEstimate.hasMaterials = true;
        delete newEstimate.materials;
      } else {
        newEstimate.hasMaterials = false; // Если materials нет, ставим hasMaterials в false
      }

      if (newEstimate.subRows) {
        newEstimate.subRows = newEstimate.subRows.map(processEstimate);
      }

      return newEstimate;
    }

    return estimates.map(processEstimate);
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (currentEstimate && file) {
      setExportSpinnerRun(true);
      dispatch(handleImportEstimate({ id: currentEstimate?.id, file }))
        .then((res: any) => {
          if (res.payload.success) {
            const intervalId = setInterval(() => {
              dispatch(fetchGetImportEstimate({ id: res.payload.data.guid }))
                .then((response: any) => {
                  if (response.payload.length > 0) {
                    const total = sumTheAmountForParents(response.payload)
                    const restructuredEstimates = restructureEstimates(total)
                    const updatedEstimate = { ...currentEstimate, details: restructuredEstimates };
                    const nonEmptyDetails = getNonEmptyQuantityItems(total)
                    dispatch(setCurrentDetails(nonEmptyDetails))
                    dispatch(changeEstimate(updatedEstimate))
                    setExportSpinnerRun(false);
                    clearInterval(intervalId);
                  } else if (response.payload === "") {

                  } else {
                    clearInterval(intervalId);
                    setExportSpinnerRun(false);
                  }
                });
            }, 2000);
          } else {
            setExportSpinnerRun(false);
          }
        })
        .finally(() => {
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        })
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentDetails([]))
      dispatch(resetCurrentImportEstimate())
    }
  }, [])


  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap={10} padding="0 24px 24px 24px">
        <Flex direction="column">
          <Flex
            direction="column"
            gap={4}
            position="sticky"
            top={0}
            paddingTop="24px"
            zIndex={2}
            bg="#F6F8FC"
            pb={4}
          >
            <Breadcrumb
              fontSize={14}
              separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
            >
              <BreadcrumbItem>
                <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href="/my-documents/contracts">Договоры</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/my-documents/contracts/${currentContract?.id}`}>
                  {currentContract?.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage color="#8089A2">
                <BreadcrumbLink href={`/my-documents/contracts/${currentContract?.id}/create-act`}>
                  Создать акт на основе сметы
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>

            <Flex justify="space-between" align="center">
              <Flex align="center" gap={4}>
                {activePage === 1 && <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />}
                <BlockTitle>Создать акт на основе сметы</BlockTitle>
              </Flex>
              <HeaderButtonGroup
                handleOpenFilesModal={handleOpenFilesModal}
                activePage={activePage}
                changePage={handleChangePage}
                onSubmit={onSubmit}
                isLoading={isRunSpinner}
                closeLoading={setIsRunSpinner}
                fieldsAreSet={fieldsAreSet}
                onOpen={onOpen}
                handleExport={handleExportClick}
                handleImport={onConfirmModalOpen}
                formik={formik}
                isInvalid={isInvalid}
                setCurrentStatus={setCurrentStatus}
                setIsDraftCreate={setIsDraftCreate}
              />
              <input type="file" accept=".xls,.xlsx" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
            </Flex>
          </Flex>

          {activePage === 1 ? (
            <>
              <MainTableBlock />
              <Box>
                <OnboardingByPermission permission={AccessRights.WorkCompletedAct_RW} />
              </Box>
            </>

          ) : (
            <SecondStage formik={formik} />
          )}
        </Flex>
      </Flex>
      <CloseFormModal isOpen={isOpen} onClose={onClose} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={[]}
        setFilesOnCreate={setFiles}
      />
      <FullScreenWaitingModal openModal={exportSpinnerRun} />
      <ConfirmImportModal isOpen={isConfirmModalOpen} onClose={isConfirmModalClose} inputRef={fileInputRef} />
      <WorkActErrorModal isOpen={isErrorModalOpen} onClose={onErrorModalClose} setActivePage={setActivePage} activePage={activePage} submitFunction={onSubmit} currentStatus={currentStatus} />
      <SuccessModal createdGuid={createdGuid} isOpen={isSuccessModalOpen} onClose={() => {
        onSuccesModalClose()
        if (createdGuid !== null) {
          navigate(`/my-documents/contracts/work-completed-act/${createdGuid}`)
        } else {
          navigate("/documents-in-progress/completed-work-acts")
        }
      }} isDraft={isDraftCreate} />
    </MainLayout>
  );
}

const SecondStage = (props: {
  formik: FormikProps<ICreateWorkCompletedActRequest>;
}) => {
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="column" gap={10}>
        <CreateActInformationBlock formik={props.formik} />
        <BuildingObjectsBlock />
        <SingleEstimateActPrintData formik={props.formik} />
        <Box>
          <OnboardingByPermission permission={AccessRights.WorkCompletedAct_RW} />
        </Box>
      </Flex>
    </Flex>
  );
};

const HeaderButtonGroup = (props: HeaderButtonGroupProps) => {
  const { handleOpenFilesModal, formik, isInvalid, setCurrentStatus, setIsDraftCreate } = props;

  return (
    <ButtonGroup spacing={3}>
      {props.activePage === 1 ? (
        <>
          <Menu>
            <OutlineMenuButton rightIcon={<ChevronDownIcon />}>
              Excel
            </OutlineMenuButton>
            <ChakraMenuList
              list={[
                {
                  title: "Загрузить текущее выполнение",
                  onClick: () => props.handleImport()
                },
                {
                  title: "Выгрузить текущее выполнение",
                  onClick: () => props.handleExport()
                },
              ]}
            />
          </Menu>
          <PrimaryButton onClick={props.changePage}>Далее</PrimaryButton>
        </>
      ) : (
        <>
          <OutlineButton onClick={props.changePage}>Назад</OutlineButton>
          <OutlineButton onClick={props.onOpen}>Отмена</OutlineButton>
          <OutlineButton
            leftIcon={<Icon as={Document} fill={"#E8363D"} />}
            gap={2}
            onClick={handleOpenFilesModal}
          >
            Файлы
          </OutlineButton>
          {!props.isLoading && (
            <Menu>
              <PrimaryMenuButton
                rightIcon={<ChevronDownIcon />}
                className={props.isInvalid ? "" : styles.disabled}
              >
                Записать
              </PrimaryMenuButton>
              <MenuList border="1px solid #E3E7EF" p={1}>
                <Divider />
                <ChakraMenuItem
                  onClick={() => {
                    setIsDraftCreate(true)
                    setCurrentStatus(ActStatuses.DraftDocument)
                    props.onSubmit(ActStatuses.DraftDocument)
                  }}
                >
                  Записать черновик
                </ChakraMenuItem>
                <ChakraMenuItem
                  onClick={() => {
                    setIsDraftCreate(false)
                    setCurrentStatus(ActStatuses.WorkingDocument)
                    props.onSubmit(ActStatuses.WorkingDocument)
                  }}
                  isDisabled={formik.values.details.length === 0}
                >
                  Записать рабочий документ
                </ChakraMenuItem>
              </MenuList>
            </Menu>
          )}

          {props.isLoading && <Loader isLoading={props.isLoading} />}

        </>
      )}
    </ButtonGroup>
  );
};

interface SuccessModalProps extends ChakraModalProps {
  createdGuid: string | null;
  isDraft: boolean
}

const SuccessModal = ({ onClose, isOpen, createdGuid, isDraft }: SuccessModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
            <img width="64px" src={SuccessLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
              {isDraft ? "Черновик создан" : "Документ отправлен"}
            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={() => {
            onClose()
            if (createdGuid !== null) {
              navigate(`/my-documents/contracts/work-completed-act/${createdGuid}`)
            } else {
              navigate("/documents-in-progress/completed-work-acts")
            }
          }}>
            Закрыть
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
