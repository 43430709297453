import { useMemo } from "react";
import { useAppDispatch } from "../../store";
import { createColumnHelper, ColumnDef, Row } from "@tanstack/react-table";
import {
  changeEstimate,
  fetchSingleEstimate,
  resetCurrentEstimate,
} from "../../store/slices/estimates";
import { Table } from "../lib/table/Table";
import { IEstimate } from "../../api/estimates/types";
import { formatAmount } from "../../helpers/formatAmount";

const columnHelper = createColumnHelper<IEstimate>();

interface EstimateTableProps {
  data: IEstimate[];
  setIsLoading: (isLoading: boolean) => void;
}

export function EstimateTable(props: Readonly<EstimateTableProps>) {
  const dispatch = useAppDispatch();

  function restructureEstimates(estimates: any[]): any[] {
    function deepClone(obj: any): any {
      if (typeof obj !== "object" || obj === null) {
        return obj;
      }

      if (Array.isArray(obj)) {
        return obj.map(deepClone);
      }

      const clonedObj: any = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          clonedObj[key] = deepClone(obj[key]);
        }
      }
      return clonedObj;
    }

    function processEstimate(estimate: any): any {
      const newEstimate = deepClone(estimate);

      if (newEstimate.materials && Array.isArray(newEstimate.materials) && newEstimate.materials.length > 0) { // Проверяем materials
        newEstimate.subRows = newEstimate.subRows || []; // Используем оператор || для инициализации
        newEstimate.materials.forEach((material: any) => {
          const newMaterial = deepClone(material);
          const updatedMaterial = { ...newMaterial, isMaterial: true };
          newMaterial.subRows = [];
          newEstimate.subRows.push(updatedMaterial);
        });
        newEstimate.hasMaterials = true;
        delete newEstimate.materials;
      } else {
        newEstimate.hasMaterials = false; // Если materials нет, ставим hasMaterials в false
      }

      if (newEstimate.subRows) {
        newEstimate.subRows = newEstimate.subRows.map(processEstimate);
      }

      return newEstimate;
    }

    return estimates.map(processEstimate);
  }

  const handleEstimatesTableRowClick = (row: Row<IEstimate>) => {
    row.toggleSelected();

    if (row.getIsSelected()) {
      dispatch(resetCurrentEstimate());
    } else {
      props.setIsLoading(true);
      dispatch(fetchSingleEstimate(row.original.id))
        .then((res:any) => {
          props.setIsLoading(false)
          const restructuredEstimates = restructureEstimates(res.payload.data.details)
          const updatedEstimate = { ...res.payload.data, details: restructuredEstimates };
          dispatch(changeEstimate(updatedEstimate))
        })
        .catch(e => {
          console.error('Error: ', e);
          props.setIsLoading(false);
        });
    }
  };

  const columns = useMemo<Array<ColumnDef<IEstimate, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
      }),
      columnHelper.accessor("Document_Amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  return (
    <Table
      data={props.data}
      columns={columns}
      dblClickDisabled={true}
      rowClick={handleEstimatesTableRowClick}
      maxHeight="370px"
    />
  );
}
