import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { BlockTitle } from "../../components/lib/title/Title"
import { InformationBlock } from "../../components/InformationBlock/InformationBlock"
import { InformationItem } from "../../components/InformationBlock/InformationItem"
import { StatusCell } from "../../components/StatusCell/StatusCell"
import { OutlineButton, PrimaryButton } from "../../components/lib/button/Button"
import { SingleOrderMaterials } from "../../components/SingleOrderComponents/SingleOrderMaterials"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { RootState, useAppDispatch, useAppSelector } from "../../store"
import { fetchCancelOrder, fetchSingleSupplierOrder } from "../../store/slices/marketplace"
import { ChakraModalProps } from "../../shared/types/modalProps"
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal"
import { formatAmount } from "../../helpers/formatAmount"

export const SingleOrderCustomerPage = () => {
    const navigate = useNavigate();
    const { customer_id } = useParams();
    const dispatch = useAppDispatch();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const { currentSupplierOrder } = useAppSelector(
        (state: RootState) => state.marketplace
    );
    const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false)


    useEffect(() => {
        customer_id && dispatch(fetchSingleSupplierOrder(customer_id))
    }, [customer_id, dispatch])
    return (
        <MainLayout>
            <Flex direction="column" gap={10} p={6}>
                <Flex direction="column" gap={4}>
                    <Breadcrumb
                        fontSize={14}
                        separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/marketplace">Маркетплейс</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/marketplace?tab=2">Заказы</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink href={`/marketplace/orders/${currentSupplierOrder?.order_guid}`}>{currentSupplierOrder?.order_name}</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage color="#8089A2">
                            <BreadcrumbLink>
                            {currentSupplierOrder?.supplier_name}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Flex align="center" gap={4}>
                            <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => navigate(-1)}/>
                            <BlockTitle>{currentSupplierOrder?.supplier_name}</BlockTitle>
                        </Flex>
                        <PrimaryButton onClick={onOpen} isDisabled={currentSupplierOrder?.status === "Отменено" || currentSupplierOrder?.status === "Закрыто"}>Отменить заказ</PrimaryButton>
                    </Flex>
                    <InformationBlock>
                        <InformationItem title="Статус">
                            <StatusCell value={currentSupplierOrder?.status ?? ""} />
                        </InformationItem>
                        <InformationItem title="Поставщик">{currentSupplierOrder?.supplier_name}</InformationItem>
                        <InformationItem title="Сумма (с НДС)">{formatAmount(currentSupplierOrder?.total_price_with_vat)}</InformationItem>
                    </InformationBlock>
                    <SingleOrderMaterials />
                </Flex>
            </Flex>
            <CancelOrderModal isOpen={isOpen} onClose={onClose} setSpinner={setIsSpinnerRunning} />
            <FullScreenWaitingModal openModal={isSpinnerRunning} />
        </MainLayout>
    )
}

interface CancelOrderModalProps extends ChakraModalProps {
    setSpinner: (arg: boolean) => void
}

function CancelOrderModal(props: Readonly<CancelOrderModalProps>) {
    const { isOpen, onClose,setSpinner } = props
    const { customer_id } = useParams();
    const dispatch = useAppDispatch();

    const handleCancelOrder = () => {
        onClose();
        setSpinner(true)
        customer_id && dispatch(fetchCancelOrder(customer_id))
            .then((res:any) => {
                if (res.payload.success) {
                    customer_id && dispatch(fetchSingleSupplierOrder(customer_id))
                }
            })
            .finally(() => setSpinner(false))
    }
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay backdropFilter="blur(3px)" />
            <ModalContent maxW="360px" gap="24px" px="24px" py="32px">
                <ModalHeader p={0}>
                    <Flex flexDirection="column" gap="12px" align="center">
                        <Text
                            fontSize="24px"
                            color="#343b4c"
                            lineHeight="28px"
                            letterSpacing="-1.5%"
                            fontWeight={500}
                            align="center">
                            Отмена заказа
                        </Text>
                        <Text fontSize={16} lineHeight="24px" fontWeight={400} color="#5C657E" align="center">Вы действительно хотите отменить заказ данному поставщику?</Text>
                    </Flex>
                </ModalHeader>
                <ModalFooter p={0}>
                    <Flex w="100%" gap="8px" align="center">
                        <OutlineButton w="full" onClick={onClose}>
                            Отмена
                        </OutlineButton>
                        <PrimaryButton w="full" onClick={handleCancelOrder}>
                            Да
                        </PrimaryButton>
                    </Flex>
                </ModalFooter>
            </ModalContent>

        </Modal>
    );
}
