import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {IDeliveryAddress, IDeliveryAddressResponse, IMarketplaceMaterial, IMarketplaceMaterialOffer, IOrderRequest, IOrdersListResponse, ISingleMaterial, ISingleOrder, ISingleOrderResponse, ISingleSupplierOrder, ISingleSupplierOrderResponse} from "../../api/marketplace/types";
import {getDeliveryAddresses, getMarketplaceMaterials, sendOrder} from "../../api/marketplace";
import {AxiosError} from "axios";
import {formatAmount} from "../../helpers/formatAmount";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import api from "../../api";

export interface MarketplaceState {
    orders: ISingleOrder[];
    currentOrder: ISingleOrder | null;
    currentSupplierOrder: ISingleSupplierOrder | null;
    marketplaceData: {
        materials: IMarketplaceMaterial[] | null;
        currentMaterial: ISingleMaterial | null;
        isLoading: boolean,
        error?: string | null;
        totalSum: string;
        deliveryAddresses: IDeliveryAddress[]
    };

}

const initialState: MarketplaceState = {
    orders: [],
    currentOrder: null,
    currentSupplierOrder: null,
    marketplaceData: {
        materials: null,
        currentMaterial: null,
        isLoading: false,
        error: null,
        totalSum: "0,00",
        deliveryAddresses: []
    },
};

export const MarketplaceNDS = 0.2;

export const fetchAllMaterials = createAsyncThunk<
    IMarketplaceMaterial[],
    undefined,
    { rejectValue: AxiosError<unknown, any> }
>(
    "marketplace/fetchAllMaterials",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await getMarketplaceMaterials();
            return data.data;
        } catch (error: unknown) {
            console.error(error);
            return rejectWithValue(error as AxiosError<unknown, any>);
        }
    }
);

export const fetchDeliveryAddresess = createAsyncThunkInstance<
  IDeliveryAddressResponse,
  undefined
>("marketplace/fetchDeliveryAddresess", api.marketplace.getDeliveryAddresses);

export const fetchOrdersList = createAsyncThunkInstance<
  IOrdersListResponse,
  undefined
>("marketplace/fetchOrdersList", api.marketplace.getOrdersList);

export const fetchSingleOrder = createAsyncThunkInstance<
  ISingleOrderResponse,
  string
>("marketplace/fetchSingleOrder", api.marketplace.getSingleOrder);

export const fetchSingleSupplierOrder = createAsyncThunkInstance<
  ISingleSupplierOrderResponse,
  string
>("marketplace/fetchSingleSupplierOrder", api.marketplace.getSingleSupplierOrder);

export const fetchCancelOrder = createAsyncThunkInstance<undefined, string>(
  "marketplace/fetchCancelOrder",
  api.marketplace.cancelOrder
);

export const fetchSendOrder = createAsyncThunk<
  undefined,
  IOrderRequest,
  { rejectValue: AxiosError }
>("marketplace/fetchSendOrder", async (params, { rejectWithValue }) => {
  try {
    const { data } = await sendOrder(params);

    return data;
  } catch (error: unknown) {
    return rejectWithValue(error as AxiosError);
  }
});

const calcOffersSum = (state: any) => {
  let total = 0;
  const materials = state.marketplaceData.materials || [];

  function calculateTotal(materials: any[]): number {
    let sum = 0;
    for (const material of materials) {
      material.offers?.forEach((offer:any) => {
        const price = parseFloat(
          offer.totalPrice?.replace(/\s/g, "").replace(",", ".") || "0"
        );
        sum += isNaN(price) ? 0 : price * (1 + MarketplaceNDS);
      });
      sum += calculateTotal(material.materials || []);
    }
    return sum;
  }

  total = calculateTotal(materials);
  state.marketplaceData.totalSum = formatAmount(total);
};

export function getObjectsFromLocalStorage() {
    const storedData = localStorage.getItem("offers");
  
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (error) {
        console.error("Ошибка при разборе JSON из localStorage:", error);
        // Если произошла ошибка разбора JSON, лучше вернуть пустой массив,
        // чем использовать поврежденные данные.
        return [];
      }
    } else {
      // Если в localStorage ничего нет, возвращаем пустой массив
      return [];
    }
  }

  export function saveObjectsToLocalStorage(objects: any) {
    try {
      const jsonData = JSON.stringify(objects);
      localStorage.setItem("offers", jsonData);
    } catch (error) {
      console.error("Ошибка при сохранении JSON в localStorage:", error);
    }
  }

  function changeFieldValue(obj: any, newValue: any, oldValue: any) {
    const newAmount = Number(newValue); // Явное преобразование к числу
    const oldAmount = Number(oldValue);

    const error =
      !isNaN(newAmount) && !isNaN(oldAmount) && oldAmount > newAmount; // Проверка на NaN

    return {
      ...obj, // Копируем существующие свойства из obj
      amount: newAmount,
      errorOffer: error,
    };
  }

  const updateMaterials = (materials: any, updatedOffer: IMarketplaceMaterialOffer):any => {
    return materials.map((material:any) => ({
      ...material,
      materials: material.materials ? updateMaterials(material.materials, updatedOffer) : undefined,
      offers: material.offers ? material.offers.map((offer:any) =>
        offer.guid === updatedOffer.guid ? updatedOffer : offer
      ) : undefined,
    }));
  };

  const findParentMaterial = (
    materials: any[] | null,
    offerGuid: string
  ): any => {
    if (materials) {
      for (const material of materials) {
        if (
          material.offers &&
          material.offers.some((offer: any) => offer.guid === offerGuid)
        ) {
          return material;
        }
        if (material.materials) {
          const parent = findParentMaterial(material.materials, offerGuid);
          if (parent) {
            return parent;
          }
        }
      }
    } else {
      return null;
    }
  };

  const updateMaterialsWithClearedOffers = (materials: any[]):any => {
    return materials.map(material => ({
      ...material,
      materials: material.materials ? updateMaterialsWithClearedOffers(material.materials) : undefined,
      offers: material.offers?.map((offer:any) => ({
        ...offer,
        quantity: "",
        totalPrice: "0,00",
      })) || undefined,
    }));
  };

  type UpdateMaterialOfferAction = {
    payload: {
        offer: IMarketplaceMaterialOffer;
        flag?: boolean;
    };
};


  const marketplaceSlice = createSlice({
    name: "marketplace",
    initialState,
    reducers: {
      setCurrentMaterial: (state, action: PayloadAction<ISingleMaterial>) => {
        state.marketplaceData.currentMaterial = action.payload;
      },
      resetCurrentMaterial: (state) => {
        state.marketplaceData.currentMaterial = null;
      },
      updateMaterialOffer: (state, action: UpdateMaterialOfferAction) => {
        if (!state.marketplaceData.materials) return;

        const updatedOffer = action.payload.offer;

        if (!updatedOffer) return;
        state.marketplaceData.materials = updateMaterials(
          state.marketplaceData.materials || [],
          updatedOffer
        );

        const currentMaterial = findParentMaterial(
          state.marketplaceData.materials,
          updatedOffer.guid
        );
        if (!action.payload.flag) {
          state.marketplaceData.currentMaterial = currentMaterial;
        }

        calcOffersSum(state);
      },
      clearOffersQuantity: (state) => {
        if (!state.marketplaceData.materials) return;

        // Обновляем offers во всех материалах
        state.marketplaceData.materials = updateMaterialsWithClearedOffers(
          state.marketplaceData.materials
        );

        //Обновляем currentMaterial.  Если currentMaterial не существует, то вызов findParentMaterial вернет null и state.marketplaceData.currentMaterial останется неизменным
        state.marketplaceData.currentMaterial = findParentMaterial(
          state.marketplaceData.materials,
          state.marketplaceData.currentMaterial?.guid || ""
        );

        state.marketplaceData.totalSum = "0,00";
      },
      calculateOffersSum: (state) => {
        calcOffersSum(state);
      },
    },

    extraReducers: (builder) => {
      builder
        .addCase(fetchAllMaterials.pending, (state) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.error = null;
        })
        .addCase(fetchAllMaterials.fulfilled, (state, action) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.materials = action.payload;
        })
        .addCase(fetchOrdersList.pending, (state) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.error = null;
        })
        .addCase(fetchOrdersList.fulfilled, (state, action) => {
          state.marketplaceData.isLoading = true;
          state.orders = action.payload.data;
        })
        .addCase(fetchSingleOrder.pending, (state) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.error = null;
        })
        .addCase(fetchSingleOrder.fulfilled, (state, action) => {
          state.marketplaceData.isLoading = true;
          state.currentOrder = action.payload.data;
        })
        .addCase(fetchSingleSupplierOrder.pending, (state) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.error = null;
        })
        .addCase(fetchSingleSupplierOrder.fulfilled, (state, action) => {
          state.marketplaceData.isLoading = true;
          state.currentSupplierOrder = action.payload.data;
        })
        .addCase(fetchDeliveryAddresess.pending, (state) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.error = null;
        })
        .addCase(fetchDeliveryAddresess.fulfilled, (state, action) => {
          state.marketplaceData.isLoading = true;
          state.marketplaceData.deliveryAddresses = action.payload.data;
        });
    },
  });

export const { setCurrentMaterial, updateMaterialOffer,clearOffersQuantity, resetCurrentMaterial } = marketplaceSlice.actions;
export const marketplaceReducer = marketplaceSlice.reducer;
