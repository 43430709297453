import { AxiosResponse } from "axios";
import { exportEstimate, getExportEstimateFile } from "../api/estimates";
import { IGetFileGuid } from "../api/estimates/types";

export const downloadExportEstimate = (
  id: number,
  data: any[],
  setIsSpinnerRuning: (arg: boolean) => void
) => {
  setIsSpinnerRuning(true);
  exportEstimate(id, data)
    .then((res: AxiosResponse<IGetFileGuid>) => {
      if (res.data.success) {
        const guid = res.data.data.guid;
        const downloadExportEstimate = (guid: string) => {
          getExportEstimateFile(guid).then((res: AxiosResponse<Blob>) => {
            if (res.status !== 204) {
              const url = window.URL.createObjectURL(
                new Blob([res.data], { type: "application/octet-stream" })
              );
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "estimate.xlsx");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              setIsSpinnerRuning(false);
            } else {
              setTimeout(() => downloadExportEstimate(guid), 2000);
            }
          });
        };
        downloadExportEstimate(guid);
      } else {
        throw new Error("Что-то пошло не так");
      }
    })
    .catch((error) => console.error(error))
};
