import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  createColumnHelper,
  CellContext,
  ColumnDef, Row,
} from "@tanstack/react-table";
import {Center, Flex, IconButton, Link, Text} from "@chakra-ui/react";
import {ReactComponent as ChatIcon} from "../../images/svg/chat/chat.svg";
import {IAdditionalRequest, IAdditionalRequestChatList} from "../../api/additionalRequests/types";
import {
  changeCurrentRequest,
  fetchAdditionalRequests,
  fetchSingleAdditionalRequest,
  resetCurrentChatList,
  resetCurrentRequest
} from "../../store/slices/additionalRequests";
import {RootState, useAppDispatch, useAppSelector} from "../../store";
import {Table} from "../lib/table/Table";
import {formatAmount} from "../../helpers/formatAmount";
import {Modal, ModalProps} from "../Modal/Modal";
import styles from "../Modal/modal.module.scss";
import {ModalHeader} from "../Modal/ModalRight/ModalHeader/ModalHeader";
import {ModalFooter} from "../Modal/ModalRight/ModalFooter/ModalFooter";
import {PrimaryButton} from "../lib/button/Button";
import modalFooterStyles from "../Modal/ModalRight/ModalFooter/modal-footer.module.scss";
import {useLocation, useNavigate} from "react-router-dom";
import { FilterHeaderMarker } from "../Filter/FilterHeaderMarker";
import { filterFunction } from "../../helpers/filterFunction";
import { FilterHeader } from "../Filter/FilterHeader";

type ChatModalProps = Omit<ModalProps, "children"> & {
  chatList?: IAdditionalRequestChatList[];
  onSendAddRequestModalOpen: () => void;
}

interface IAddRequestTable {
  onSendAddRequestModalOpen: () => void;
}

const columnHelper = createColumnHelper<IAdditionalRequest>();

const TimeCell = (info: CellContext<IAdditionalRequest, string>) => {

  return (
    <Text
      fontSize="16px"
      lineHeight="20px"
      color="#343B4C"
    >
      {info.getValue()}
    </Text>
  )
}

const TypeCell = (info: CellContext<IAdditionalRequest, boolean>) => {
  const isVisa = info.getValue();

  return (
    <Text
      fontSize="14px"
      lineHeight="14px"
      py={2}
      px={3}
      color="#343B4C"
      bg={isVisa ? "#AAE2DB" : "#FEE7AC"}
      borderRadius="6px"
      display="inline-flex"
    >
      {isVisa ? "Виза" : "Доп. запрос"}
    </Text>
  )
}

const DocumentCell = (info: CellContext<IAdditionalRequest, string>) => {
  const isLink = info.row.original.document_id && info.row.original.document_type ? true : false;
  let chapter = ""
  switch (info.row.original.document_type) {
    case "access_act":
      chapter = "my-documents/access-acts"; break;
    case "limit_card":
      chapter = "purchase/stock-cards"; break;
    case "contract":
      chapter = "my-documents/contracts"; break;
    case "direction_letter":
      chapter = "purchase/order-letters"; break;
    case "invoice":
      chapter = "documents-in-progress/invoices"; break;
    case "work_completed_act":
      chapter = "my-documents/contracts/work-completed-act"; break;
    case "savings_compensation_act":
      chapter = "purchase/compensation-act"; break;
    case "reconciliation_statement":
      chapter = "add-request"; break;
  }

  const handleClickLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    chapter !== "" && window.open(`/${chapter}/${info.row.original.document_id}`, '_self');
  }
  return (
    isLink ? (
      <Link color="#2AB6A5" onClick={handleClickLink}>{info.renderValue()}</Link>
    ) : (
      <Text noOfLines={2}>{info.renderValue()}</Text>
    )
  );
};

const LineClampCell = (info: CellContext<IAdditionalRequest, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

function isEllipsisActive(e:HTMLDivElement) {
  return (e.offsetHeight < e.scrollHeight);
}

const MessageCell = (info: CellContext<IAdditionalRequest, string>) => {
  const text = info.renderValue();
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  const checkEllipsis = () => {
    if (textRef.current) {
      const isEllipsed = isEllipsisActive(textRef.current);
      setIsEllipsis(isEllipsed);
    }
  };

  useEffect(() => {
    checkEllipsis();

    window.addEventListener('resize', checkEllipsis);

    return () => {
      window.removeEventListener('resize', checkEllipsis);
    };
  }, []);

  return (
    <Text
      data-tooltip-id={isEllipsis ? "tooltip" : ""}
      data-tooltip-html={isEllipsis ? text : null}
      noOfLines={2}
      ref={textRef}
    >
      {text}
    </Text>
  );
};

export function AddRequestTable({onSendAddRequestModalOpen}: IAddRequestTable) {
  const { additionalRequests, currentChatList } = useAppSelector((state) => state.additionalRequests);
  const dispatch = useAppDispatch();
  const [chatModal, setChatModal] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const ChatCell = useCallback((info: CellContext<IAdditionalRequest, string>): JSX.Element => {
    const hasChat = info.row.original.has_chat;

    return(
      <Center>
        {hasChat &&
          <IconButton
            aria-label="chat"
            color="#2AB6A5"
            bg="none"
            _hover={{ bg: "none" }}
            _disabled={{ bg: "none" }}
            icon={<ChatIcon />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              setChatModal(true);
              dispatch(fetchSingleAdditionalRequest(info.row.original.guid));
            }}
          />
        }
      </Center>
    )}, [dispatch]);

  const handleRowClick = (row: Row<IAdditionalRequest>) => {
    row.toggleSelected();

    if (row.getIsSelected()) {
      dispatch(resetCurrentRequest());
      dispatch(resetCurrentChatList());
    } else {
      dispatch(fetchSingleAdditionalRequest(row.original.guid));
      dispatch(changeCurrentRequest(row.original));
    }
  };

  const columns = useMemo<Array<ColumnDef<IAdditionalRequest, any>>>(
    () => [
      columnHelper.accessor("hours", {
        header: "Время",
        cell: TimeCell,
        enableSorting: true,
      }),
      columnHelper.accessor("is_visa", {
        header: (info) =>
          FilterHeaderMarker(
            "Тип",
            info.column,
            "Виза",
            "Доп. запрос"
          ),
        cell: TypeCell,
      }),
      columnHelper.accessor("document_name", {
        header: "Документ",
        cell: DocumentCell,
      }),
      columnHelper.accessor("project_name", {
        header: "Проект",
        cell: LineClampCell,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
      columnHelper.accessor("document_type_name", {
        header: (info) => FilterHeader(info.column, info.table, "Тип документа"),
        cell: LineClampCell,
        enableColumnFilter: true,
        filterFn: ({ original: { document_type_name } }, id, filterValues) =>
          filterFunction({ value: document_type_name, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("message", {
        header: "Сообщение",
        cell: MessageCell,
      }),
      {
        id: "has_chat",
        header: "Чат",
        cell: ChatCell,
      },
    ],
    [ChatCell]
  );
  return (
    <>
      <Table
        minHeight="300px"
        data={additionalRequests}
        columns={columns}
        rowClick={handleRowClick}
        dblClickDisabled={true}
        isGuid={true}
        fetchFunction={fetchAdditionalRequests}
      />
      <ChatModal chatList={currentChatList} active={chatModal} onClose={() => setChatModal(false)} onSendAddRequestModalOpen={onSendAddRequestModalOpen} />
    </>
  );
}

function ChatModal(props: ChatModalProps) {
  const { active, onClose, chatList = [], onSendAddRequestModalOpen } = props;
  const {currentAdditionalRequest} = useAppSelector((state: RootState) => state.additionalRequests);

  const handleSendBtnClick = () => {
    onClose();
    onSendAddRequestModalOpen();
  };

  return (
    <Modal active={active} onClose={onClose}>
      <div
        className={styles.modal_content}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles.container}>
          <ModalHeader title="Чат" onClose={onClose} />
          <form className={styles.form}>
            <div className={styles.modal_info}>
              {chatList.map((messageBlock, idx) => (
                <Flex
                  key={idx}
                  borderRadius="12px"
                  gap="16px"
                  padding="24px"
                  bgColor="#F0F3FA"
                  flexDirection="column"
                >
                  <Flex
                    maxW="428px"
                    flexDirection="column"
                    gap="6px"
                    alignSelf="end"
                    key={`${messageBlock.message}-${idx}`}
                  >
                    <Text fontWeight={500} alignSelf="end">
                      Вопрос
                    </Text>
                    <Text
                      px="16px"
                      py="12px"
                      borderRadius="12px 4px 12px 12px"
                      backgroundColor="#CBD0DE"
                      color="#343B4C"
                      fontSize={16}
                      lineHeight="20px"
                    >
                      {messageBlock.message}
                    </Text>
                  </Flex>
                  {messageBlock.answer !== "" && (
                    <Flex
                      maxW="428px"
                      flexDirection="column"
                      gap="6px"
                      alignSelf="start"
                      key={`${messageBlock.answer}-${idx}`}
                    >
                      <Text fontWeight={500} alignSelf="start">
                        Ответ
                      </Text>
                      <Text
                        px="16px"
                        py="12px"
                        borderRadius="12px 4px 12px 12px" 
                        backgroundColor="#FFFFFF"
                        color="#343B4C"
                        fontSize={16}
                        lineHeight="20px"
                      >
                        {messageBlock.answer}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              ))}
            </div>
            <ModalFooter>
              <PrimaryButton
                onClick={handleSendBtnClick}
                className={modalFooterStyles.auto_width_btn}
                isDisabled={!currentAdditionalRequest?.can_create_request}
              >
                Отправить доп. запрос на визу
              </PrimaryButton>
            </ModalFooter>
          </form>
        </div>
      </div>
    </Modal>
  );
}
