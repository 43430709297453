import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    Box,
    Flex,
    Text,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { fetchDeliveryAddresess } from "../../store/slices/marketplace";
import { FormikProps } from "formik";
import { IOrderRequest } from "../../api/marketplace/types";

interface MultiselectMenuProps {
    isDisabled?: boolean
    formik: FormikProps<IOrderRequest>
    setSelectedAdress: (arg: string) => void;
    selectedAdress: string
}

export function AddressDropDown(props: MultiselectMenuProps) {
    const { isDisabled = false, formik, setSelectedAdress,  selectedAdress} =
        props;
    const { marketplaceData } = useAppSelector((state) => state.marketplace);

    const dispatch = useAppDispatch();

    const { isOpen, onToggle, onClose } = useDisclosure()
    const ref = useRef(null);
    useOutsideClick({ ref, handler: onClose });

    const handleDocumentSelect = (address: any) => {
        setSelectedAdress(address.address)
        formik.setFieldValue("address_guid", address.guid)
        onClose()
    };

    useEffect(() => {
        dispatch(fetchDeliveryAddresess())
    }, [])


    return (
        <Accordion
            backgroundColor="#FFFFFF"
            color="#5C657E"
            borderRadius="8px"
            position="relative"
            marginLeft="-1px"
            ref={ref}
            w="20vw"
        >
            <AccordionItem outline="none" border={selectedAdress ? "1px solid #E3E7EF" : "1px solid #E53E3E"} borderRadius="6px" h="44px" background={isDisabled ? "#F0F3FA" : ""} isDisabled={isDisabled}>
                <h2>
                    <AccordionButton p={0}
                        cursor="default"
                        onClick={onToggle}
                        data-tooltip-id={selectedAdress ? "tooltip" : ""}
                        data-tooltip-content={selectedAdress ? selectedAdress : null}
                    >
                        <Flex
                            margin="10px 12px"
                            w="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box fontWeight={400} as="span" flex="1" textAlign="left">
                                <Text color="#343B4C" noOfLines={1}>{selectedAdress ? `${selectedAdress}` : ""}</Text>
                            </Box>
                            <Flex alignItems="center">
                                <Text color="#8089A2" fontSize="12px" fontWeight={400}>Адрес доставки</Text>
                                {isOpen ? (
                                    <ChevronUpIcon boxSize={6} color="#2AB6A5" />
                                ) : (
                                    <ChevronDownIcon boxSize={6} color="#2AB6A5" />
                                )}
                            </Flex>

                        </Flex>
                    </AccordionButton>
                </h2>
                {isOpen && (
                    <Box
                        position="absolute"
                        top="46px"
                        left="0"
                        width="100%"
                        bg="white"
                        zIndex="10"
                        overflowY="auto"
                        maxHeight="300px"
                        borderRadius="6px"
                        border="1px solid #E3E7EF"
                        padding="4px"
                        sx={{
                            boxShadow: "0px 8px 24px 0px #1F222A0D",
                        }}
                    >
                        <Flex >
                            {marketplaceData.deliveryAddresses.length > 0 && (
                                <Flex w="100%" gap="2px" flexDirection="column">
                                    {marketplaceData.deliveryAddresses.map((address) => (
                                        <Flex
                                            w="100%"
                                            paddingLeft="16px"
                                            paddingRight="16px"
                                            paddingTop="8px"
                                            paddingBottom="8px"
                                            borderBottom="1px solid #E3E7EF"
                                            alignItems="center"
                                            cursor="pointer"
                                            _hover={{
                                                cursor: "pointer",
                                                backgroundColor: "#F0F3FA",
                                            }}
                                            onClick={() => handleDocumentSelect(address)}
                                        >
                                            <Text
                                                w="100%"
                                                color={
                                                    selectedAdress === address.address
                                                        ? "#2AB6A5"
                                                        : "#343B4C"
                                                }
                                                fontSize="16px"
                                                lineHeight="16px"
                                            >
                                                {`${address.address}`}
                                            </Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                )}
            </AccordionItem>
        </Accordion>
    );
}