import { Link } from "@chakra-ui/react";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { StatusCell } from "../StatusCell/StatusCell";
import { RootState, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { fetchSingleInvoice } from "../../store/slices/invoices";
import { formatAmount } from "../../helpers/formatAmount";
import { numberWithDate } from "../../helpers/numberWithDate";
import { getFullTime } from "../../helpers/getFullTime";

export function SingleInvoiceInformationBlock({ goBack }: any) {
  const [status, setStatus] = useState<string>("");

  const { currentInvoice } = useAppSelector(
    (state: RootState) => state.invoices
  );

  useEffect(() => {
    if (currentInvoice?.status) {
      setStatus(currentInvoice?.status);
    }
  }, [currentInvoice?.status]);

  return (
    <InformationBlock>
      <InformationItem title="Номер">
        {currentInvoice?.invoice_number ? numberWithDate(currentInvoice?.invoice_number, currentInvoice?.invoice_date) : ""}
      </InformationItem>
      <InformationItem title="Номер вх.">
        {currentInvoice?.included_document_number}
      </InformationItem>
      <InformationItem title="Организация">
        {currentInvoice?.a101_org_name}
      </InformationItem>
      <InformationItem title="Контрагент">
        {currentInvoice?.organization_name}
      </InformationItem>
      <InformationItem title="Договор">
        <Link
          onClick={() => {
            goBack(`/my-documents/contracts/${currentInvoice?.contract_id}`);
          }}
          color="#2AB6A5"
        >
          {currentInvoice?.contract_name}
        </Link>
      </InformationItem>
      <InformationItem title="Документ на основании">
        {currentInvoice?.document_based_on}
      </InformationItem>
      <InformationItem title="Статус">
        <StatusCell value={status} />
      </InformationItem>
      <InformationItem title="Дата статуса">
        {currentInvoice?.invoice_date ? getFullTime(currentInvoice?.invoice_date) : ""}
      </InformationItem>
      <InformationItem title="Сумма">{currentInvoice?.amount ? formatAmount(currentInvoice?.amount) : ""}</InformationItem>
      <InformationItem title="Ответственный">
        {currentInvoice?.responsibly_by}
      </InformationItem>
    </InformationBlock>
  );
}
