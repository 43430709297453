import {TableHTMLAttributes, useEffect, useMemo, useState} from "react";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
  ColumnDef,
  ExpandedState,
  getExpandedRowModel,
  Row,
} from "@tanstack/react-table";
import { Box, Flex } from "@chakra-ui/react";
import classNames from "classnames";
import { MemoizedFilter } from "../../Filter/Filter";
import { SortIcon } from "../../SortIcon/SortIcon";
import styles from "./table.module.scss";
import { IDetail, IDetailField } from "../../../api/estimates/types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setCurrentDetails, setTableState } from "../../../store/slices/estimates";
import { debounce } from "lodash";

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  data: Array<any>;
  columns: ColumnDef<any, string>[];
  rowClick?: (row: Row<any>) => void;
  onRowSelect?: (row: Row<any>) => void;
  dblClickDisabled?: boolean;
  searchValue: string;
}

export const ActTable = (props: Readonly<TableProps>) => {
  const {
    data,
    columns,
    rowClick,
    dblClickDisabled,
    searchValue,
    ...rest
  } = props;

  const searchFields: IDetailField[] = [
    "Codifier", "TypeofWorkName", "UnitOfMeasurementName", "ConstructionObjectName", "Price",
    "Quantity", "AmountEstimate", "Thenumberofstops", "AmountRemaining",
    "quantityinthedocument", "PercentageOfCompletion"
  ];

  const { tableState } = useAppSelector((state) => state.estimates);


  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      enableColumnFilter: false,
      enableSorting: false,
    },
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  function expandOnSearch(row: Row<IDetail>){
    if(row.getCanExpand()){
      let childWithSearchValue = false

      row.subRows.forEach(childRow => {
        const rowChildWithSearchValue:any = expandOnSearch(childRow)
        childWithSearchValue = childWithSearchValue || rowChildWithSearchValue
      })

      const haveSearchValue = !!(searchValue && searchFields.some((field) => String(row.original[field]).toLowerCase().includes(String(searchValue).toLowerCase())))
      row.toggleExpanded(childWithSearchValue)
      return haveSearchValue || childWithSearchValue
    }
    else{
      const haveSearchValue = !!(searchValue && searchFields.some((field) => String(row.original[field]).toLowerCase().includes(String(searchValue).toLowerCase())))
      return haveSearchValue
    }
  }

  useEffect( () =>{
    if(tableState){
      table.setState(tableState)
    }
    else{
      table.reset()
    }
  }, [])

  const checkedRows = table.getSelectedRowModel().flatRows;

  useEffect(() => {
    table.getRowModel().rows.forEach(
      row => {
        const childWithSearchValue = expandOnSearch(row)
        row.toggleExpanded(childWithSearchValue)
      }
    )
  }, [searchValue])

  return (
    <Box overflowX="scroll" overflowY="scroll" maxH="67vh">
      <div className={styles.main_table_container} style={{
        maxWidth: "max-content"
      }} {...rest}>
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    <Flex
                      gap="10px"
                      justify="space-between"
                      className={styles.inner_head_cell}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      {header.column.getCanSort() && (
                        <div onClick={header.column.getToggleSortingHandler()}>
                          <SortIcon sort={header.column.getIsSorted()} />
                        </div>
                      )}
                      {header.column.getCanFilter() && (
                        <MemoizedFilter column={header.column} table={table} />
                      )}
                    </Flex>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={classNames({
                  [styles.checked]:
                    row.getIsSelected() || row.getIsExpanded(),
                  [styles.errorLzk]: row.original.isError,
                  [styles.finded]: searchValue && searchFields.some((field) => String(row.original[field]).toLowerCase().includes(String(searchValue).toLowerCase()))
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={cell.column.columnDef.meta?.cellClassName && styles[cell.column.columnDef.meta?.cellClassName]}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Box>
  );
};
