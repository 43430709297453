import {useEffect, useMemo, useState} from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import { Input } from "../lib/input/Input";
import { Table } from "../lib/table/Table";
import { fetchContracts, resetCurrentContract } from "../../store/slices/contracts";
import { IContract } from "../../api/contracts/types";
import { formatAmount } from "../../helpers/formatAmount";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { filterFunction } from "../../helpers/filterFunction";
import { FilterHeader } from "../Filter/FilterHeader";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";

const columnHelper = createColumnHelper<IContract>();

interface TitleProps {
    onChange: (inp: string) => void;
}

const TitleHeader = ({onChange}: TitleProps) => {
  return (
    <Flex align="center" justify="space-between" w="full" gap="16px">
      <span>Название</span>
      <Input small search placeholder="Поиск" onChange={(e) => onChange(e.target.value)}/>
    </Flex>
  );
};

const TitleCell = ({ row: { original } }: CellContext<IContract, string>) => {
  return (
    <Flex align="center" justify="space-between" gap="16px">
      <span>{original.name}</span>
      <Flex minW={12} gap={3} color="#2AB6A5">
        {original.has_files && <AttachmentIcon cursor="pointer" />}
      </Flex>
    </Flex>
  );
};


const ProjectCell = ({ row: { original } }: CellContext<IContract, string>) => (
  <Box>
    <Text>{original.project_name}</Text>
    <Text fontSize="14px" lineHeight="22px" color="#8089A2">
      {original.executor}
    </Text>
  </Box>
);

export function ContractsTable() {
  const { contracts, currentContract } = useAppSelector(
    (state: RootState) => state.contracts
  );
  const [search, setSearch] = useState("");
  const dispatch = useAppDispatch();
  const onChange = (str: string) => {
    setSearch(str);
  };

  useEffect(() => {
    return () => {
      dispatch(resetCurrentContract());
    };
  }, []);

  const columns = useMemo<Array<ColumnDef<IContract, string>>>(
    () => [
      columnHelper.accessor("name", {
        header: () => TitleHeader({ onChange }),
        cell: TitleCell,
      }),
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues }),
        meta: {
          filterSelect: true,
        },
      }),
      columnHelper.accessor("contract_date", {
        header: "Дата",
        cell: (info) => dayjs(info.renderValue()).format("DD.MM.YYYY"),
        enableSorting: true,
      }),
      columnHelper.accessor("project_name", {
        header: (info) => FilterHeader(info.column, info.table, "Проект и исполнитель"),
        enableColumnFilter: true,
        enableHiding: false,
        cell: ProjectCell,
        filterFn: ({ original: { project_name } }, id, filterValues) =>
          filterFunction({ value: project_name, filterValues }),
        meta: {
          filterSelect: true
        }
      }),
      columnHelper.accessor("short_description_construction_object", {
        header: "Объект строительства",
      }),
      columnHelper.accessor("cost", {
        header: "Сумма",
        cell: (info) => formatAmount(info.getValue()),
      }),
    ],
    []
  );

  return (
    <Flex flexDirection="column" gap="8px">
      <Table
        data={
          search.trim() === ""
            ? contracts
            : contracts.filter((it) =>
                it.name.toLowerCase().includes(search.toLowerCase())
              )
        }
        columns={columns}
        defaultSorting={[{ id: "contract_date", desc: true }]}
        fetchFunction={fetchContracts}
        lastViewedDocument={currentContract?.id}
        minHeight="300px"
      />
        <Box>
          <OnboardingByPermission permission={AccessRights.Contract_RO} />
        </Box>
    </Flex>

  );
}
