import {useEffect, useState} from "react";
import {RootState, useAppSelector} from "../../store";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink, ButtonGroup,
  Divider,
  Flex, Icon, Menu, MenuList, useDisclosure
} from "@chakra-ui/react";
import {
  resetCurrentAccessAct,
} from "../../store/slices/accessActs";
import { ArrowBackIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {BlockTitle} from "../../components/lib/title/Title";
import {MainLayout} from "../../components/MainLayout/MainLayout";
import {CreateActInformationBlock} from "../../components/CreateActBasedOnEstimateComponents/CreateActInformationBlock";
import {FormikProps, useFormik} from "formik";
import {ICreateWorkCompletedActRequest, IWorkCompletedActDetail} from "../../api/workCompletedActs/types";
import {addWorkCompletedAct, attachFilesToSingleWorkCompletedAct, fetchSingleWorkCompletedActs, modifyWorkCompletedAct, resetCurrenWorkCompletedAct} from "../../store/slices/workCompletedActs";
import * as Yup from "yup";
import {OutlineButton, PrimaryButton} from "../../components/lib/button/Button";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import styles from "../CreateActBasedOnEstimatePage/create-act.module.scss";
import {Loader} from "../../components/Loader/Loader";
import {CloseFormModal} from "../../components/CloseFormModal/CloseFormModal";
import {SingleEstimateActPrintData} from "../../components/SingleEstimateActPrintData/SingleEstimateActPrintData";
import {checkPermission} from "../../helpers/checkPermission";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { BuildingObjectsBlock } from "../../components/SingleWorkCompletedActComponents/BuildingObjectBlock";
import { fetchSingleEstimate, resetCurrentEstimate } from "../../store/slices/estimates";
import { ProgressElement } from "../../components/SingleWorkCompletedActComponents/ProgressElement";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { IFilesAttachResponse } from "../../api/files/types";
import { PrimaryMenuButton } from "../../components/lib/menu/ChakraMenuButton";
import { ChakraMenuItem } from "../../components/lib/menu/ChakraMenuList";
import { russianPatternCheck } from "../../helpers/russianPatternCheck";
import { fetchSingleContract, resetCurrentContract } from "../../store/slices/contracts";

interface HeaderButtonGroupProps {
  onSubmit: (value: string) => void;
  isLoading: boolean;
  closeLoading: (arg: boolean) => void;
  fieldsAreSet: () => boolean;
  onOpen: () => void;
  setFilesModal: (arg:boolean) => void
  isInvalid: boolean
}

export function SingleWorkCompletedAct({ goBack, dispatch, filesModal, setFilesModal, id }: any) {
  const { currentWorkCompletedAct, detailsWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const { currentVat } = useAppSelector((state) => state.vats);

  const [isRunSpinner, setIsRunSpinner] = useState(false);


  const onSubmit = (status: string) => {
    let modifyStatus = status
    if (status === "" && currentWorkCompletedAct) {
      modifyStatus = currentWorkCompletedAct.status
    }


    if (currentWorkCompletedAct) {
      setIsRunSpinner(true);
      dispatch(
        modifyWorkCompletedAct({ id: id, params: {...formik.values, status: modifyStatus} })
      )
        .then(() => { setIsRunSpinner(false); goBack(); })
        .catch((e: Error) => {
          console.error('Error: ', e);
          setIsRunSpinner(false);
        });
    }
  };

  const transformDetails = (details: IWorkCompletedActDetail[]) =>
    details?.map((detail: IWorkCompletedActDetail) => ({
      ConstructionObjectID: detail.construction_object_guid,
      TypeofWork: detail.type_of_work_guid,
      Price: Number(detail.price),
      Total: Number(detail.total),
      quantity: Number(detail.quantity)
    }));

  const formik: FormikProps<ICreateWorkCompletedActRequest> =
    useFormik<ICreateWorkCompletedActRequest>({
      initialValues: {
        invoice_number: currentWorkCompletedAct?.invoice_number ?? "",
        invoice_date: currentWorkCompletedAct?.invoice_date ?? "",
        act_number: currentWorkCompletedAct?.act_number ?? "",
        act_date: currentWorkCompletedAct?.act_date ?? "",
        sf_number: currentWorkCompletedAct?.sf_number ?? "",
        sf_date: currentWorkCompletedAct?.sf_date ?? "",
        manager_name: currentWorkCompletedAct?.manager_name ?? "",
        manager_name_power_of_attorney: currentWorkCompletedAct?.manager_name_power_of_attorney ?? "",
        chief_accountant: currentWorkCompletedAct?.chief_accountant ?? "",
        chief_accountant_power_of_attorney: currentWorkCompletedAct?.chief_accountant_power_of_attorney ?? "",
        date_from: currentWorkCompletedAct?.date_from ?? "",
        date_to: currentWorkCompletedAct?.date_to ?? "",
        status: "",
        contract_id: currentWorkCompletedAct?.contract_id ?? 0,
        estimate_id: currentWorkCompletedAct?.estimate_id ?? 0,
        details: [],
        advance_payments: currentWorkCompletedAct?.advance_payments,
        executive_documentation: currentWorkCompletedAct?.executive_documentation ?? "",
        vat: currentVat ?? "",
      },
      validationSchema: Yup.object({
        invoice_number: Yup.string().required(),
        invoice_date: Yup.string().required(),
        executive_documentation: Yup.string().required(),
        act_number: Yup.string().required(),
        act_date: Yup.string().required(),
        sf_number: Yup.string().required(),
        sf_date: Yup.string().required(),
        manager_name: Yup.string().required(),
        manager_name_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
            Yup.string().required() : Yup.number().positive().nullable()
        ),
        chief_accountant: Yup.string().required(),
        chief_accountant_power_of_attorney: Yup.lazy((value) =>
          typeof value === 'string' ?
            Yup.string().required() : Yup.number().positive().nullable()
        ),
        date_from: Yup.string().required(),
        date_to: Yup.string().required(),
        status: Yup.string().required(),
        contract_id: Yup.number().required(),
        estimate_id: Yup.number().required(),
        details: Yup.array().required(),
      }),
      onSubmit: () => {},
      enableReinitialize: false,
      validateOnBlur: false,
      validateOnMount: false,
    });

    useEffect(() => {
      formik.setFieldValue("vat", currentVat)
    }, [currentVat])
  
    useEffect(() => {
      formik.setFieldValue("details", detailsWorkCompletedAct.map((detail) => {
        const result = {
        ConstructionObjectID: detail.construction_object_guid,
        TypeofWork: detail.type_of_work_guid,
        quantity: Number(detail.quantity),
        Price: Number(detail.price),
        Total: Number(detail.total),
        vat: detail.vat
      }
      return result;
    }))
    }, [detailsWorkCompletedAct])

  const fieldsAreSet = () => {
    return formik.values.invoice_number.trim() !== '' && formik.values.invoice_date !== '' &&
      formik.values.act_number.trim() !== '' && formik.values.act_date !== '' &&
      formik.values.sf_number.trim() !== '' && formik.values.sf_date !== '' &&
      formik.values.manager_name.trim() !== '' && formik.values.chief_accountant.trim() !== '' &&
      formik.values.date_from !== '' && formik.values.date_to !== '' && formik.values.executive_documentation !== "";
  }

  useEffect(() => {
    const requiredFieldsFilled =
      formik.values.invoice_number.trim() !== '' &&
      formik.values.invoice_date !== '' &&
      formik.values.act_number.trim() !== '' &&
      formik.values.act_date !== '' &&
      formik.values.sf_number.trim() !== '' &&
      formik.values.sf_date !== '' &&
      formik.values.manager_name.trim() !== '' &&
      formik.values.chief_accountant.trim() !== '' &&
      formik.values.date_from !== '' &&
      formik.values.date_to !== '' &&
      russianPatternCheck(formik.values.manager_name) &&
      russianPatternCheck(formik.values.chief_accountant) &&
      formik.values.executive_documentation !== "";

    const advancePaymentsFilled = formik.values.advance_payments ?
      formik.values.advance_payments.every(item => (item.date !== "" && item.name !== "") || (item.date === "" && item.name === "")) :
      true;

    setIsInvalid(requiredFieldsFilled && advancePaymentsFilled);
  }, [formik.values])

  const progressBlocks = [
    { t: "РС", p: currentWorkCompletedAct?.progress_rs },
    { t: "ТН", p: currentWorkCompletedAct?.progress_tn },
    { t: "Бэк-офис", p: currentWorkCompletedAct?.progress_back_office }
  ]

  const [files, setFiles] = useState(currentWorkCompletedAct?.files ?? []);

  const fileAttach = (files: File[]) => {
    if (currentWorkCompletedAct) {
      dispatch(attachFilesToSingleWorkCompletedAct({id: currentWorkCompletedAct.id, files: files})).then((res: { payload: IFilesAttachResponse}) => {
        setFiles((res.payload as IFilesAttachResponse)?.data.files);
      });
    }
  }

  useEffect(() => {
    setFiles(currentWorkCompletedAct?.files ?? []);
    currentWorkCompletedAct && dispatch(fetchSingleContract(currentWorkCompletedAct?.contract_id))
  }, [currentWorkCompletedAct, dispatch])

  useEffect(() => {
  
    id && dispatch(fetchSingleWorkCompletedActs(id))
      .then((res: any) => {
        if (res.payload.success) {
          dispatch(fetchSingleEstimate(res.payload.data.estimate_id))
        }
      })

    return () => {
      dispatch(resetCurrentAccessAct());
    };
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentContract())
      dispatch(resetCurrentEstimate())
    }
  }, [])

  useEffect(() => {
    formik.setFieldValue("invoice_number", currentWorkCompletedAct?.invoice_number ?? "")
    formik.setFieldValue("invoice_date", currentWorkCompletedAct?.invoice_date ?? "")
    formik.setFieldValue("act_number", currentWorkCompletedAct?.act_number ?? "")
    formik.setFieldValue("act_date", currentWorkCompletedAct?.act_date ?? "")
    formik.setFieldValue("sf_number", currentWorkCompletedAct?.sf_number ?? "")
    formik.setFieldValue("sf_date", currentWorkCompletedAct?.sf_date ?? "")
    formik.setFieldValue("manager_name", currentWorkCompletedAct?.manager_name ?? "")
    formik.setFieldValue("manager_name_power_of_attorney", currentWorkCompletedAct?.manager_name_power_of_attorney ?? "")
    formik.setFieldValue("chief_accountant", currentWorkCompletedAct?.chief_accountant ?? "")
    formik.setFieldValue("chief_accountant_power_of_attorney", currentWorkCompletedAct?.chief_accountant_power_of_attorney ?? "")
    formik.setFieldValue("date_from", currentWorkCompletedAct?.date_from ?? "")
    formik.setFieldValue("date_to", currentWorkCompletedAct?.date_to ?? "")
    formik.setFieldValue("contract_id", currentWorkCompletedAct?.contract_id ?? "")
    formik.setFieldValue("estimate_id", currentWorkCompletedAct?.estimate_id ?? "")
    formik.setFieldValue("advance_payments", currentWorkCompletedAct?.advance_payments ?? [])
    formik.setFieldValue("executive_documentation", currentWorkCompletedAct?.executive_documentation ?? "")
  },[currentWorkCompletedAct])


  return (
    <MainLayout>
      <Flex overflowY="scroll" maxH="100%" direction="column" gap="24px" p={6}>
        <Flex direction="column" gap="16px">
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%"/>}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/my-documents/contracts">Договоры</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`/my-documents/contracts/${currentWorkCompletedAct?.contract_id}`}>{currentWorkCompletedAct?.contract_name}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink href="#">
                Акт на основе сметы
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/documents-in-progress/completed-work-acts")}/>
              <BlockTitle>
                Акт на основе сметы
              </BlockTitle>
            </Flex>
            <HeaderButtonGroup
              onSubmit={onSubmit}
              isLoading={isRunSpinner}
              closeLoading={setIsRunSpinner}
              fieldsAreSet={fieldsAreSet}
              onOpen={onOpen}
              setFilesModal={setFilesModal}
              isInvalid={isInvalid}
            />
          </Flex>
          <Flex justify="space-between" align="center" gap="12px">
            {progressBlocks.map(el => <ProgressElement key={el.t} title={el.t} percent={el.p ?? 0} />)}
          </Flex>
        </Flex>

        <CreateActInformationBlock isUpdateDocument={true} formik={formik} organization="" accountantPoAInputDisabled={formik.values.chief_accountant_power_of_attorney === ""} managerPoAInputDisabled={formik.values.manager_name_power_of_attorney === ""} />
        <BuildingObjectsBlock />
        <SingleEstimateActPrintData formik={formik} />
        <Box>
          <OnboardingByPermission permission={AccessRights.WorkCompletedAct_RO} />
        </Box>
      </Flex>
      <CloseFormModal isOpen={isOpen} onClose={onClose} />
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        isDisabled={currentWorkCompletedAct?.status=="Утверждена"}
        fileAttach={fileAttach}
        receivedFiles={files}
        onClose={() => setFilesModal(false)}
      />
    </MainLayout>
  );
}

const HeaderButtonGroup = (props: HeaderButtonGroupProps) => {
  const { currentWorkCompletedAct } = useAppSelector((state) => state.workCompletedActs);

  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  return (
    <ButtonGroup spacing={3}>
      <OutlineButton 
        isDisabled={!checkPermission(AccessRights.WorkCompletedAct_RW, permissions) || !currentWorkCompletedAct?.can_edit}
        onClick={props.onOpen}>
          Отмена
      </OutlineButton>
      <OutlineButton
        leftIcon={<Icon as={Document} fill={"#E8363D"} />}
        gap={2}
        isDisabled={!checkPermission(AccessRights.WorkCompletedAct_RW, permissions)}
        onClick={() => props.setFilesModal(true)}
      >
        Файлы
      </OutlineButton>
      {!props.isLoading && (
        <Menu>
          <PrimaryMenuButton
            rightIcon={<ChevronDownIcon />}
            className={props.isInvalid ? "" : styles.disabled}
          >
            Записать
          </PrimaryMenuButton>
          <MenuList border="1px solid #E3E7EF" p={1}>
            <Divider />
            <ChakraMenuItem
              onClick={() => props.onSubmit("Черновик ЛКП")}
              isDisabled={currentWorkCompletedAct?.status !== "Черновик ЛКП"}
            >
              Записать черновик
            </ChakraMenuItem>
            <ChakraMenuItem
              onClick={() => props.onSubmit((currentWorkCompletedAct && currentWorkCompletedAct?.status === 'Черновик ЛКП') ? "Рабочая" : "")}
              isDisabled={!checkPermission(AccessRights.WorkCompletedAct_RW, permissions) || !currentWorkCompletedAct?.can_edit}
            >
              Записать рабочий документ
            </ChakraMenuItem>
          </MenuList>
        </Menu>
      )}

      {props.isLoading &&
        <Loader isLoading={props.isLoading} />
      }

    </ButtonGroup>
  );
};
