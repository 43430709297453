import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { MainLayout } from "../../components/MainLayout/MainLayout"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { BlockTitle } from "../../components/lib/title/Title"
import { InformationBlock } from "../../components/InformationBlock/InformationBlock"
import { InformationItem } from "../../components/InformationBlock/InformationItem"
import { StatusCell } from "../../components/StatusCell/StatusCell"
import { useEffect, useMemo } from "react"
import { ColumnDef, createColumnHelper } from "@tanstack/react-table"
import { Table } from "../../components/lib/table/Table"
import { SingleOrderMaterials } from "../../components/SingleOrderComponents/SingleOrderMaterials"
import { useNavigate, useParams } from "react-router-dom"
import { RootState, useAppDispatch, useAppSelector } from "../../store"
import { fetchSingleOrder } from "../../store/slices/marketplace"
import { ISupplier } from "../../api/marketplace/types"
import { formatOrderDate } from "../../helpers/formatOrderDate"
import { formatAmount } from "../../helpers/formatAmount"
import { TitleCell } from "../../components/SingleOrderComponents/TitleCell"

const columnHelper = createColumnHelper<ISupplier>();

const TABLE_COLUMNS: Array<ColumnDef<ISupplier, string>> = [
    columnHelper.accessor("name", {
        header: "Поставщик",
        cell: TitleCell,
    }),
    columnHelper.accessor("supplier_order_status", {
        header: "Статус",
        cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("supplier_order_total_price_with_vat", {
        header: "Сумма (с НДС)",
        cell: (info) => formatAmount(info.getValue()),
    }),
];

export const SingleOrderPage = () => {
    const { order_id } = useParams();
    const dispatch = useAppDispatch();
    const { currentOrder } = useAppSelector(
        (state: RootState) => state.marketplace
    );
    const navigate = useNavigate();

    const columns = useMemo<Array<ColumnDef<ISupplier, string>>>(
        () => TABLE_COLUMNS,
        []
    );

    useEffect(() => {
        if (order_id) {
            dispatch(fetchSingleOrder(order_id));
        }
    }, [order_id, dispatch]);

    const orderTitle = currentOrder ? `${currentOrder.number} от ${formatOrderDate(currentOrder.date_create)}` : '';

    return (
        <MainLayout>
            <Flex direction="column" gap={10} p={6}>
                <Flex direction="column" gap={4}>
                    <Breadcrumb
                        fontSize={14}
                        separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
                    >
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/marketplace">Маркетплейс</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink href="/marketplace?tab=2">Заказы</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage color="#8089A2">
                            <BreadcrumbLink>{orderTitle}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Flex align="center" gap={4}>
                        <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => navigate(-1)} />
                        <BlockTitle>{orderTitle}</BlockTitle>
                    </Flex>
                    <Grid templateColumns="1fr 1fr" gap="24px">
                        <GridItem>
                           <InformationBlock>
                                <InformationItem title="Статус">
                                    <StatusCell value={currentOrder?.status ?? ""}/>
                                </InformationItem>
                                <InformationItem title="Номер">{currentOrder?.number}</InformationItem>
                                <InformationItem title="Дата заказа">{formatOrderDate(currentOrder?.date_create)}</InformationItem>
                                <InformationItem title="Адрес">{currentOrder?.address}</InformationItem>
                                <InformationItem title="Комментарий">{currentOrder?.comment}</InformationItem>
                                <InformationItem title="Сумма (с НДС)">{formatAmount(currentOrder?.total_price_with_vat)}</InformationItem>
                            </InformationBlock>
                        </GridItem>
                        <GridItem>
                            <Table 
                                columns={columns} 
                                data={currentOrder?.suppliers ?? []} 
                                maxHeight="300px" 
                            />
                        </GridItem>
                    </Grid>
                    <SingleOrderMaterials />
                </Flex>
            </Flex>
        </MainLayout>
    );
};