import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex, Icon, IconButton,
  Menu, MenuButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {ArrowBackIcon} from "@chakra-ui/icons";
import * as Yup from "yup";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { BlockTitle } from "../../components/lib/title/Title";
import { ChakraTabs } from "../../components/lib/tabs/Tabs";
import { ChakraMenuList } from "../../components/lib/menu/ChakraMenuList";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { singleOrderLetterPageTabs, singleOrderLetterPageTabsWithoutMaterials } from "../../shared/mock/purchasePageData";
import {ReactComponent as MenuIcon} from "../../images/svg/menu/more.svg";
import { ReactComponent as FileShare} from "../../images/svg/file/share.svg";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { useEffect, useState } from "react";
import { CreateOrderLetterComponent } from "../../components/CreateOrderLetterComponents/CreateOrderLetterComponent";
import { AdditionalInformationCreate } from "../../components/CreateOrderLetterComponents/AdditionalInformationCreate";
import { useFormik } from "formik";
import { addDirectionLetter } from "../../store/slices/directionLetters";
import { FullScreenWaitingModal } from "../../components/Modal/FullScreenWaitingModal";
import { resetEditebleLzk, resetSupplierMaterials } from "../../store/slices/limitFenceCards";
import { SingleAdvanceType } from "../../components/SingleAdvanceType/SingleAdvanceType";
import { MaterialTableBlock } from "../../components/SingleOrderLetterComponents/MaterialTableBlock/MaterialTableBlock";
import { fetchSingleContract } from "../../store/slices/contracts";
import { IPaymentRp } from "../../api/workCompletedActs/types";
import { ChakraModalProps } from "../../shared/types/modalProps";
import SuccessLogo from "../../images/png/success.png";

export function CreateOrderLetterPage({ filesModal, setFilesModal, goBack }: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get("tab"));
  const navigate = useNavigate();
  const { currentLimitFenceCard, currentSupplierMaterials } = useAppSelector((state: RootState) => state.limitFenceCards);
  const { currentContract } = useAppSelector((state: RootState) => state.contracts);
  const [isInvalid, setIsInvalid] = useState<boolean>(true)
  const { currentSupplier, selectedAccount } = useAppSelector((state: RootState) => state.supplier);
  const [isSpinnerRuning, setIsSpinnerRuning] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { isOpen: isSuccessModalOpen, onClose: onSuccesModalClose, onOpen: onSuccesModalOpen } = useDisclosure();
  const [createdGuid, setCreatedGuid] = useState<string | null>(null)

  const setDefaultTabParam = (index: number): void => {
    index === 0
      ? searchParams.delete("tab")
      : searchParams.set("tab", String(index));

    setSearchParams(searchParams);
  };


  useEffect(() => {
    if (!currentLimitFenceCard && !currentContract) {
      navigate("/purchase/stock-cards")
    }
  }, [currentLimitFenceCard, currentContract,dispatch])

  useEffect(() => {
    if (currentLimitFenceCard) {
      dispatch(fetchSingleContract(Number(currentLimitFenceCard.contract_id)))
    }
  }, [currentLimitFenceCard, dispatch])

  const onSubmit = () => {}


  const { setFieldValue, ...formik } = useFormik({
    initialValues: {
      supplier_director_full_name: currentSupplier?.director_full_name,
      supplier_director_role: currentSupplier?.director_role,
      payment_assignment: "",
      invoice_number: "",
      invoice_date: "",
      order_number: "",
      order_date: "",
      supplier_contract_number: "",
      supplier_contract_date: "",
      specification_number: "",
      specification_date: "",
      supplier_inn: currentSupplier?.inn ?? "",
      supplier_kpp: currentSupplier?.kpp ?? "",
      supplier_name: currentSupplier?.name ?? "",
      supplier_guid: currentSupplier?.guid ?? "",
      checking_account_guid: selectedAccount?.guid ?? "",
      checking_account_number: selectedAccount?.number ?? "",
      checking_account_bic: selectedAccount?.bic ?? "",
      relation_direction_letter_name: "",
      relation_direction_letter_date: "",
      supplier_invoice_number: "",
      supplier_invoice_date: "",
      amount: "",
      tax: "",
      contract_id: currentLimitFenceCard ? Number(currentLimitFenceCard.contract_id) : currentContract?.id,
      limit_card_id: currentLimitFenceCard?.id,
      materials: currentSupplierMaterials ?? [],
      advance_payment_type: "",
      advance_payment_schedule: []
    },
    validationSchema: Yup.object({
      invoice_number: Yup.string().required(),
      invoice_date: Yup.string().required(),
      order_number: Yup.string().required(),
      order_date: Yup.string().required(),
      specification_number: Yup.string().required(),
      specification_date: Yup.string().required(),
      amount: Yup.number().required(),
      tax: Yup.number().required(),
      relation_direction_letter_name: Yup.string().required(),
      relation_direction_letter_date: Yup.string().required(),
      supplier_contract_number: Yup.string().required(),
      supplier_contract_date: Yup.string().required(),
    }),
    onSubmit,
  });

  const filterMaterialFields = (material:any) => ({
    guid: material.guid,
    material_guid: material.material_guid,
    order_number: material.order_number,
    amount: material.amount,
  });

  useEffect(() => {
    const filteredMaterials = currentSupplierMaterials.map(filterMaterialFields);
    setFieldValue("materials", filteredMaterials);
  }, [currentSupplierMaterials, setFieldValue]);

  const isScheduleRowComplete = (row:any) => {
    return row && row.date !== "" && row.amount !== "";
  };

  const sumScheduleAmounts = (schedule:any) => {
    if (!schedule || schedule.length === 0) {
      return 0;
    }
    return schedule.reduce((sum:any, row:any) => {
      const amount = parseFloat(row.amount) || 0;
      return sum + amount
    }, 0);
  };
  const fieldsAreSet = () => {
    const {
      invoice_number,
      invoice_date,
      order_number,
      order_date,
      amount,
      tax,
      relation_direction_letter_name,
      relation_direction_letter_date,
      supplier_contract_number,
      supplier_contract_date,
      payment_assignment,
      supplier_invoice_number,
      supplier_invoice_date,
      advance_payment_type,
      advance_payment_schedule,
    } = formik.values;

    const basicFieldsAreSet =
      invoice_number.trim() !== "" &&
      invoice_date !== "" &&
      (order_number.trim() !== "" || currentContract?.format === "Договор с номинированными материалами") &&
      (order_date !== "" || currentContract?.format === "Договор с номинированными материалами") &&
      amount !== "" &&
      tax !== "" &&
      relation_direction_letter_name.trim() !== "" &&
      relation_direction_letter_date !== "" &&
      supplier_contract_number.trim() !== "" &&
      supplier_contract_date !== "" &&
      payment_assignment !== "" &&
      supplier_invoice_number.trim() !== "" &&
      advance_payment_type.trim() !== "" &&
      supplier_invoice_date !== "";

    let scheduleIsValid = true;
    let amountIsValid = true;
    if (advance_payment_type === "По графику") {
      if (!advance_payment_schedule || advance_payment_schedule.length === 0) {
        scheduleIsValid = false;
      }
      else {
        scheduleIsValid = advance_payment_schedule.every(isScheduleRowComplete);
        const scheduleSum = sumScheduleAmounts(advance_payment_schedule);
        amountIsValid = parseFloat(amount) === scheduleSum;
      }
    }

    return (
      basicFieldsAreSet &&
      (advance_payment_type !== "По графику" ||
        (advance_payment_type === "По графику" && scheduleIsValid && amountIsValid))
    );
  };

  useEffect(() => {
    setIsInvalid(!fieldsAreSet())
  }, [formik.values])

  const handleSubmit = () => {
    setIsSpinnerRuning(true)
    dispatch(addDirectionLetter(formik.values))
      .then((res: any) => {
        if (res.payload.success) {
          setCreatedGuid(res.payload.data.guid)
          onSuccesModalOpen();
        }
      })
      .finally(() => setIsSpinnerRuning(false))
  }

  useEffect(() => {
    return () => {
      dispatch(resetEditebleLzk())
    }
  }, [])


  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/profile">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase">Закупки</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/purchase/order-letters">
                Распорядительные письма
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color="#8089A2">
              <BreadcrumbLink>Создать распорядительное письмо</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack()} />
              <BlockTitle>Создать распорядительное письмо</BlockTitle>
            </Flex>
            <ButtonGroup spacing={3}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<MenuIcon color="red.500" />}
                  variant="outline"
                  borderColor="#FAD7D8"
                  _active={{ bg: "#FAD7D8", borderColor: "#F1868B" }}
                  isDisabled={true}
                />
                <ChakraMenuList
                  list={[
                    {
                      title: "Отозвать оплату",
                    },
                    {
                      title: "Печать заявки на выплату аванса",
                    },
                    {
                      title: "Создать отражение факта поставки",
                    },
                  ]}
                />
              </Menu>
              <OutlineButton
                leftIcon={<Icon as={FileShare} />}
                onClick={() => setFilesModal(true)}
                isDisabled={true}
              >
                Файлы
              </OutlineButton>
              <OutlineButton isDisabled={true}>Согласовать</OutlineButton>
              <OutlineButton isDisabled={true}>Отозвать</OutlineButton>
              <PrimaryButton isDisabled={isInvalid} onClick={handleSubmit}>Записать</PrimaryButton>
            </ButtonGroup>
          </Flex>

          <Tabs defaultIndex={tabIndex} onChange={setDefaultTabParam}>
            <ChakraTabs
              tabs={
                currentLimitFenceCard
                  ? singleOrderLetterPageTabs
                  : singleOrderLetterPageTabsWithoutMaterials
              }
            />
            <TabPanels>
              <TabPanel px={0} pb={0}>
                <CreateOrderLetterComponent formik={formik} setFieldValue={setFieldValue}/>
              </TabPanel>
              {currentLimitFenceCard && (
                <TabPanel px={0} pb={0}>
                  <MaterialTableBlock setFieldValue={setFieldValue} />
                </TabPanel>
              )}
              <TabPanel px={0} pb={0}>
                <AdditionalInformationCreate formik={formik} setFieldValue={setFieldValue} setFilesModal={setFilesModal} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        <Box>
          <OnboardingByPermission permission={AccessRights.Purchase_RW} />
        </Box>

      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={[]}
        fileAttach={() => { }}
      />
      <FullScreenWaitingModal openModal={isSpinnerRuning} />
      <SuccessModal createdGuid={createdGuid} isOpen={isSuccessModalOpen} onClose={() => {
        onSuccesModalClose()
        if (createdGuid !== null) {
          navigate(`/purchase/order-letters/${createdGuid}`)
        } else {
          navigate("/purchase/order-letters")
        }
      }} />
    </MainLayout>
  );
}
interface SuccessModalProps extends ChakraModalProps {
  createdGuid: string | null
}

const SuccessModal = ({ onClose, isOpen, createdGuid }: SuccessModalProps) => {
  const navigate = useNavigate()
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(3px)" />
      <ModalContent h={300}>
        <ModalBody>
          <Flex gap="12px" flexDirection="column" w="100%" h="100%" alignItems="center" justifyContent="center">
          <img width="64px" src={SuccessLogo} alt="Error" />
            <Text
              fontSize="24px"
              color="#1F222A"
              lineHeight="28px"
              letterSpacing="-1.5%"
              textAlign="center"
              fontWeight={500}>
              Распорядительное письмо успешно создано
            </Text>
          </Flex>

        </ModalBody>
        <ModalFooter>
          <OutlineButton onClick={() => {
            onClose();
            if (createdGuid !== null) {
              navigate(`/purchase/order-letters/${createdGuid}`)
            } else {
              navigate("/purchase/order-letters")
            }
          }}>
            Закрыть
          </OutlineButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
