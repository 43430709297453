import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { Table } from "../../lib/table/Table";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { fetchOrdersList } from "../../../store/slices/marketplace";
import { ISingleOrder } from "../../../api/marketplace/types";
import { Flex, Text } from "@chakra-ui/react";
import { convertDate } from "../../../helpers/convertDate";
import { formatOrderDate } from "../../../helpers/formatOrderDate";
import { formatAmount } from "../../../helpers/formatAmount";
import { FilterHeader } from "../../Filter/FilterHeader";
import { filterFunction } from "../../../helpers/filterFunction";
import { InformationBlock } from "../../InformationBlock/InformationBlock";
import { OutlineButton, PrimaryButton } from "../../lib/button/Button";
import { PrimaryMenuButton } from "../../lib/menu/ChakraMenuButton";

const columnHelper = createColumnHelper<ISingleOrder>();


const SupplierCell = ({ row }: CellContext<any, string>) => {
    const suppliers = row.original.suppliers || [];
    const supplierNames = suppliers.map((supplier:any) => supplier.name);
    const supplierNamesString = supplierNames.join(', ');
    return (
        <Text>{supplierNamesString}</Text>
    )
}

export const MarketplaceOrders = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { orders } = useAppSelector(
        (state: RootState) => state.marketplace
    );

    function navigateToPage(id: number) {
        navigate(`/marketplace/orders/${id}`);
    }

    useEffect(() => {
        dispatch(fetchOrdersList())
    }, [])

    const columns = useMemo<Array<ColumnDef<ISingleOrder, any>>>(
        () => [
            columnHelper.accessor("status", {
                header: (info) => FilterHeader(info.column, info.table, "Статус"),
                cell: (info) => info.renderValue(),
                enableColumnFilter: true,
                filterFn: ({ original: { status } }, id, filterValues) =>
                    filterFunction({ value: status, filterValues }),
                meta: {
                    filterSelect: true
                },
            }),
            columnHelper.accessor("number", {
                header: "Номер",
                cell: (info) => info.renderValue(),
                enableColumnFilter: true,
            }),
            columnHelper.accessor("date_create", {
                header: "Дата заказа",
                cell: (info) => formatOrderDate(info.renderValue()),
                enableSorting: true,
            }),
            columnHelper.accessor("suppliers", {
                header: (info) => FilterHeader(info.column, info.table, "Поставщик", true),
                cell: SupplierCell,
                enableColumnFilter: true,
                filterFn: ({ original: { suppliers } }, id, filterValues) =>
                    filterFunction({ value: suppliers, filterValues, isObject: true }),
                meta: {
                    filterSelect: true
                },
            }),
            columnHelper.accessor("address", {
                header: "Адрес",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.accessor("total_price_with_vat", {
                header: "Сумма (с НДС)",
                cell: (info) => formatAmount(info.renderValue()),
            }),
        ],
        []
    );
    return (
        <>
            {(orders.length > 0) ? (
                <Table data={orders} columns={columns} dblClickHandler={navigateToPage} isGuid={true} minHeight="300px" defaultSorting={[{ id: "date_create", desc: true }]} />
            ) : (
                <Flex textAlign="center" gap="16px" alignItems="center" justifyContent="center" flexDirection="column" w="100%" h="60vh" border="1px solid #E3E7EF" borderRadius="8px">
                    <Text fontSize="24px" fontWeight={500} color="#343B4C">Здесь пока ничего нет</Text>
                    <Text maxW="507px" color="#8089A2" fontSize="16px">
                        Заказать нужные материалы можно из каталога, предварительно добавив их в корзину
                    </Text>
                    <Flex gap="8px">
                        <OutlineButton onClick={() => navigate("/marketplace")}>Перейти в каталог</OutlineButton>
                        <PrimaryButton onClick={() => navigate("/marketplace?tab=1")}>Перейти в корзину</PrimaryButton>
                    </Flex>
                </Flex>
            )}
        </>

    )
}