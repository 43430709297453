import { Box, Flex, Input, Select } from "@chakra-ui/react";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { InformationItemForm } from "../InformationBlock/InformationItemForm";
import { RootState, useAppSelector } from "../../store";
import { OutlineButton, PrimaryButton } from "../lib/button/Button";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { reverseDate } from "../../helpers/reverseDate";
import { fetchPrintForm } from "../../store/slices/directionLetters";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { useUniversalDownloadFile } from "../../helpers/useUniversalDownloadFile";
import { IAccount } from "../../api/supplier/types";
import { SupplierDropdown } from "../SingleOrderLetterComponents/SupplierDrowdown";
import { SingleAdvanceType } from "../SingleAdvanceType/SingleAdvanceType";

interface AdditionalInformationBlockProps {
  setFilesModal: (arg: boolean) => void;
  formik: any;
  setFieldValue: any
}

const testData: IAccount[] = [
  {
    guid: "b0d4ce1d-1157-4699-138c-cfa72ba94f86",
    number: "1",
    bic: "12345678901",
    bank_name: "ЧЕЛЯБИНСКОЕ",
  },
  {
    guid: "b0d4ce1d-1157-4699-138c-cfa72ssba94f86",
    number: "2",
    bic: "12345678901",
    bank_name: "СБЕРБАНК",
  },
];

export function AdditionalInformationCreate({
  setFilesModal, formik, setFieldValue
}: AdditionalInformationBlockProps) {
  const { currentDirectionLetter } = useAppSelector((state: RootState) => state.directionLetters);
  const { profileData } = useAppSelector((state: RootState) => state.profile);
  const { currentSupplier, selectedAccount } = useAppSelector((state: RootState) => state.supplier);
  const { currentContract } = useAppSelector((state: RootState) => state.contracts);
  const { currentLimitFenceCard } = useAppSelector((state: RootState) => state.limitFenceCards);
  const { downloadFile } = useUniversalDownloadFile();

  const isDisabled = currentDirectionLetter?.has_approved_visa && currentDirectionLetter.status !== "Рабочий"

  const [selectedData, setSelectedData] = useState<any>();
  const [isSpinnerRuning, setIsSpinnerRuning] = useState(false);


  useEffect(() => {
    setFieldValue(
      "supplier_director_full_name",
      currentDirectionLetter?.supplier_director_full_name ??
      currentSupplier?.director_full_name
    );
    setFieldValue(
      "supplier_director_role",
      currentDirectionLetter?.supplier_director_role ??
      currentSupplier?.director_role
    );

    setFieldValue(
      "supplier_inn",
      currentSupplier?.guid ?? ""
    );
    setFieldValue(
      "supplier_kpp",
      currentSupplier?.kpp ?? ""
    );
    setFieldValue(
      "supplier_name",
      currentSupplier?.name ?? ""
    );
    setFieldValue(
      "supplier_guid",
      currentSupplier?.guid ?? ""
    );
    setFieldValue(
      "checking_account_guid",
      selectedAccount?.guid ?? ""
    );
    setFieldValue(
      "checking_account_number",
      selectedAccount?.number ?? ""
    );
    setFieldValue(
      "checking_account_bic",
      selectedAccount?.bic ?? ""
    );

  }, [setFieldValue, currentDirectionLetter, currentSupplier, selectedAccount]);


  return (
    <Flex flexDirection="column" gap="24px">
      <Flex flexDirection="column" gap="16px">
        <InformationBlock>
          <InformationItem title={`Счет с ${currentSupplier?.name}`}>
            <InformationItemForm
              firstId="supplier_invoice_number"
              secondId="supplier_invoice_date"
              firstValue={formik.values.supplier_invoice_number}
              secondValue={formik.values.supplier_invoice_date}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
            />
          </InformationItem>
          <InformationItem title={`Счет с ${currentLimitFenceCard?.a101_org_name ?? currentContract?.a101_org_name}`}>
            <InformationItemForm
              firstId="invoice_number"
              secondId="invoice_date"
              firstValue={formik.values.invoice_number}
              secondValue={formik.values.invoice_date}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
            />
          </InformationItem>
          <InformationItem title="Номер заявки">
            <InformationItemForm
              firstId="order_number"
              secondId="order_date"
              firstValue={formik.values.order_number}
              secondValue={formik.values.order_date}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
              required={currentContract?.format !== "Договор с номинированными материалами"}
            />
          </InformationItem>
          <InformationItem title="Договор с поставщиком">
            <InformationItemForm
              firstId="supplier_contract_number"
              secondId="supplier_contract_date"
              firstValue={formik.values.supplier_contract_number}
              secondValue={formik.values.supplier_contract_date}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
            />
          </InformationItem>
          <InformationItem title="Спецификация">
            <InformationItemForm
              firstId="specification_number"
              secondId="specification_date"
              firstValue={formik.values.specification_number}
              secondValue={formik.values.specification_date}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
              required={false}
            />
          </InformationItem>
          <InformationItem title={`Р/С  ${currentSupplier?.name}`} isAccordion>
            <SupplierDropdown documents={currentSupplier?.accounts ?? []} setFieldValue={setFieldValue} />
          </InformationItem>
          <InformationItem title="Руководитель">
            <Input
              id="supplier_director_full_name"
              name="supplier_director_full_name"
              h="36px"
              rounded="md"
              borderColor="#E3E7EF"
              position="absolute"
              top="50%"
              transform="translate(0, -50%)"
              value={formik.values.supplier_director_full_name}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
            />
          </InformationItem>
          <InformationItem title="Должность руководителя">
            <Input
              id="supplier_director_role"
              name="supplier_director_role"
              defaultValue={currentSupplier?.director_role}
              h="36px"
              rounded="md"
              borderColor="#E3E7EF"
              position="absolute"
              top="50%"
              transform="translate(0, -50%)"
              value={formik.values.supplier_director_role}
              onChange={formik.handleChange}
              isDisabled={isDisabled}
            />
          </InformationItem>
          <InformationItem title="БИК">
            {selectedAccount ? (
              `${selectedAccount?.bic}`
            ) : (
              <Input
                id="checking_account_bic"
                name="checking_account_bic"
                h="36px"
                rounded="md"
                borderColor="#E3E7EF"
                position="absolute"
                top="50%"
                transform="translate(0, -50%)"
                value={formik.values.checking_account_bic}
                onChange={formik.handleChange}
              />
            )}

          </InformationItem>
          <InformationItem title="Название отделения банка">
            {selectedAccount ? (
              `${selectedAccount?.bank_name}`
            ) : (
              "")}
          </InformationItem>
          <InformationItem title="Назначение платежа">
            <Input
              id="payment_assignment"
              name="payment_assignment"
              h="36px"
              rounded="md"
              borderColor="#E3E7EF"
              position="absolute"
              top="50%"
              transform="translate(0, -50%)"
              isDisabled={isDisabled}
              value={formik.values.payment_assignment}
              onChange={formik.handleChange}
              isInvalid={formik.values.payment_assignment === ""}
            />
          </InformationItem>
        </InformationBlock>
        <FullScreenWaitingModal openModal={isSpinnerRuning} />
      </Flex>
      {currentContract?.advance_minimum_percentage === null && <SingleAdvanceType setFieldValue={setFieldValue} formik={formik} />}
    </Flex>
  );
}