import React, { useEffect, useState } from 'react';
import { Flex, Text, Box, Icon } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

export const MaterialItem = ({ material, handleMaterialClick, currentMaterial, expanded }: any) => {
    const [isOpen, setIsOpen] = useState(expanded);

    const hasChildren = material.materials && material.materials.length > 0;

    const toggleDropdown = (e:any) => {
        e.stopPropagation(); // Предотвращаем срабатывание handleMaterialClick на родительском элементе
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setIsOpen(expanded)
    }, [expanded])

    return (
        <Box>
            <Flex
                cursor="pointer"
                backgroundColor={currentMaterial?.guid == material.guid ? "#F0F3FA" : "white"}
                id={material.guid}
                borderTop="1px solid #E3E7EF"
                padding="16px"
                alignItems="center"
                onClick={toggleDropdown}
            >
                {hasChildren && (
                     <Icon
                     cursor="pointer"
                     mr={2}
                     fontSize="12px"
                     as={isOpen ? MinusIcon : AddIcon}
                   />
                )}
                <Text fontSize="16px" lineHeight="20px">
                    {`${material.name} (${material.materials.length})`}
                </Text>
            </Flex>

            {isOpen && hasChildren && (
                <Box paddingLeft="16px">
                    {material.materials.map((childMaterial:any, index:any) => (
                        <Flex
                            key={index}
                            cursor="pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleMaterialClick(childMaterial);
                            }}
                            backgroundColor={currentMaterial?.guid == childMaterial.guid ? "#F0F3FA" : "white"}
                            borderTop="1px solid #E3E7EF"
                            padding="12px"
                            alignItems="center"
                        >
                            <Text fontSize="14px" lineHeight="18px">
                                {childMaterial.name}
                            </Text>
                        </Flex>
                    ))}
                </Box>
            )}
        </Box>
    );
};