import { Box, Flex, Text, Link } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { INotification } from "../../api/notifications/types";
import { viewNotification } from "../../api/notifications";

interface NotificationItemProps {
  item: INotification;
}

function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export function NotificationItem(props: NotificationItemProps) {
  const { id, title, created_at, content, is_viewed = false } = props.item;
  const [isViewed, setIsViewed] = useState(is_viewed);

  const clickNotifications = useCallback(async () => {
    try {
      const {data} = await viewNotification(id)
      if(data.success){
        setIsViewed(true)
      }
      return data;
    } catch (error) {
      throw error;
    }
  }, []);
  return (
    <Flex
      direction="column"
      gap={1}
      p="16px 24px"
      borderBottom="1px solid #E3E7EF"
      position="relative"
      onClick={() => clickNotifications()}
    >
      <Flex>
        <Text fontWeight={500} lineHeight="20px" flex={1}>
          {title}
        </Text>
        <Text fontSize="12px" color="#8089A2" whiteSpace="nowrap">
          {formatDate(created_at)}
        </Text>
      </Flex>
      <Box fontSize="14px" lineHeight="18px" dangerouslySetInnerHTML={{ __html: content}} />
      {!isViewed && (
        <Box w={1} h="full" position="absolute" bg="#E8363D" left={0} top={0} />
      )}
    </Flex>
  );
}
