import { useEffect, useMemo } from "react";
import { Table } from "../lib/table/Table"
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { IOrderMaterial } from "../../api/marketplace/types";
import { useNavigate, useParams } from "react-router-dom";
import { FilterHeader } from "../Filter/FilterHeader";
import { filterFunction } from "../../helpers/filterFunction";
import { fetchAllMaterials, setCurrentMaterial } from "../../store/slices/marketplace";
import { Text } from "@chakra-ui/react";


const columnHelper = createColumnHelper<IOrderMaterial>();

export const SingleOrderMaterials = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { currentOrder, currentSupplierOrder, marketplaceData } = useAppSelector(
        (state: RootState) => state.marketplace
    );

    const { customer_id } = useParams();

    useEffect(() => {
        dispatch(fetchAllMaterials())
    }, [dispatch])

    const MaterialCell = ({ row }: CellContext<IOrderMaterial, string>) => {

        function findMaterialByOfferGuid(data:any[], targetGuid: string) {
            for (const item of data) {
                if (item.materials && Array.isArray(item.materials)) {
                    for (const material of item.materials) {
                        if (material.offers && Array.isArray(material.offers)) {
                            for (const offer of material.offers) {
                                if (offer.guid === targetGuid) {
                                    return material;
                                }
                            }
                        }
                    }
                }
            }
            return null; // Ничего не нашли
        }
        return (
            <Text onClick={() => {
                marketplaceData.materials && dispatch(setCurrentMaterial(findMaterialByOfferGuid(marketplaceData.materials, row.original.supplier_material_guid)))
                navigate("/marketplace")
            }}>{row.original.supplier_material_name}</Text>
        )
    }

    const columns = useMemo<Array<ColumnDef<IOrderMaterial, string>>>(
        () => [
            columnHelper.accessor("number", {
                header: "№",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("order_supplier_material_status", {
                header: "Статус",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("supplier_name", {
                header: "Поставщик",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("supplier_material_name", {
                cell: MaterialCell,
                header: (info) => FilterHeader(info.column, info.table, "Материал (1С)"),
                enableColumnFilter: true,
                filterFn: ({ original: { supplier_material_name } }, id, filterValues) =>
                    filterFunction({ value: supplier_material_name, filterValues, isObject: true }),
                meta: {
                    filterSelect: true
                },
            }),
            columnHelper.accessor("material_name", {
                header: "Материал",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("supplier_material_article", {
                header: "Артикул",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("supplier_material_price", {
                header: "Цена",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("amount", {
                header: "Количество",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("total_price_with_vat", {
                header: "Сумма (с НДС)",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("vat", {
                header: "НДС",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("total_price", {
                header: "Сумма без НДС",
                cell: (info) => info.getValue(),
            }),
        ],
        []
    );
    const columnsCustomer = useMemo<Array<ColumnDef<IOrderMaterial, string>>>(
        () => [
            columnHelper.accessor("number", {
                header: "№",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("order_supplier_material_status", {
                header: "Статус",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("material_name", {
                header: "Материал",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("supplier_material_article", {
                header: "Артикул",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("supplier_material_price", {
                header: "Цена",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("amount", {
                header: "Количество",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("total_price_with_vat", {
                header: "Сумма (с НДС)",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("vat", {
                header: "НДС",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("total_price", {
                header: "Сумма без НДС",
                cell: (info) => info.getValue(),
            }),
        ],
        []
    );
    return (
        <Table columns={customer_id ? columnsCustomer : columns} data={(customer_id ? currentSupplierOrder?.materials : currentOrder?.materials) ?? []} maxHeight="450px"  dblClickDisabled={true} />
    )
}