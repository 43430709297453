import { toast, Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalModal } from 'react-global-modal'
import { OutlineButton, PrimaryButton } from '../lib/button/Button';
import { Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const ShowNotification = (status:number, errorMessage: string, containsEntity: boolean) => {

    const notify = () => toast("Что-то пошло не так. Попробуйте снова", 
        {
            transition: Zoom, closeOnClick: false, className: "notify__class", autoClose: 2000,
            hideProgressBar: true,
        })

    const openModalError = async () => {
        GlobalModal.push({
            component: ErrorModal,
            hideHeader: true,
            modalSize: "xs",
            contentClassName: "modalError",
        })
    }
    const openModalErrorEntity = async () => {
        GlobalModal.push({
            component: ErrorModalEntity,
            hideHeader: true,
            modalSize: "xs",
            contentClassName: "modalError",
        })
    }

    const openModalErrorContent = async (errorMessage: string) => {
        GlobalModal.push({
            component: ErrorContentModal,
            hideHeader: true,
            modalSize: "xs",
            contentClassName: "modalError",
            props: { errorMessage }
        })
    }

    const openModalPermission = async () => {
        GlobalModal.push({
          component: PermissionErrorModal,
          hideHeader: true,
          modalSize: "xs",
          contentClassName: "modalError",
          onClose: () => {
            window.location.href="/profile"
        },
        })
      }
    const openModalAuth = async () => {
        GlobalModal.push({
          component: AuthErrorModal,
          hideHeader: true,
          modalSize: "xs",
          contentClassName: "modalError",
          onClose: () => {
            localStorage.removeItem("token");
            window.location.href="/"
        },
        })
      }

    if(status == 403){
        openModalPermission()
    }
    else if(status == 401){
        
    }
    else if(status == 422){
        openModalErrorContent(errorMessage)
    }
    else if (containsEntity){
        openModalErrorEntity()
    }
    else{
        openModalError()
    }

}

const PermissionErrorModal = () => {
    return (
        <div>
            <p className='text'>Доступ запрещен</p>
            <PrimaryButton className='button' onClick={() => {
                GlobalModal.closeAll()
                window.location.href = "/profile"
                }}>
                Закрыть
            </PrimaryButton>
        </div>
    )
}

const AuthErrorModal = () => {
    return (
        <div>
            <p className='text'>Ошибка авторизации</p>
            <PrimaryButton className='button' onClick={() => {
                localStorage.removeItem("token");
                GlobalModal.closeAll()
                window.location.href="/"
                }}>
                Закрыть
            </PrimaryButton>
        </div>
    )
}

const ErrorModal = () => {
    return (
        <div>
            <p className='text'>Что-то пошло не так. Попробуйте снова</p>
            <PrimaryButton className='button' onClick={() => {
                GlobalModal.closeAll()
            }}>
                Закрыть
            </PrimaryButton>
        </div>
    )
}

const ErrorContentModal = ({errorMessage}: {errorMessage: string}) => {
    return (
        <div>
            <p className='text'>{errorMessage}</p>
            <PrimaryButton className='button' onClick={() => {
                GlobalModal.closeAll()
            }}>
                Закрыть
            </PrimaryButton>
        </div>
    )
}

const ErrorModalEntity = () => {
    const navigate = useNavigate()
    return (
        <div>
            <p className='text'>Что-то пошло не так. Попробуйте снова</p>
            <Flex justifyContent="space-between">
            <PrimaryButton onClick={() => {
                navigate(-1)
                GlobalModal.closeAll()
                }}>
                Назад
            </PrimaryButton>
            <OutlineButton onClick={() => {
                window.location.reload()
                GlobalModal.closeAll()
                }}>
                Обновить
            </OutlineButton>
            </Flex>
        </div>
    )
}
