import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {MarketplaceEndpoints, } from "../endpoints";
import { IAllMaterialsResponse, IDeliveryAddressResponse, IOrderRequest, IOrdersListResponse, ISingleOrderResponse, ISingleSupplierOrderResponse } from "./types";

export const getMarketplaceMaterials =
  (): AxiosPromise<IAllMaterialsResponse> =>
    axiosInstance.get(MarketplaceEndpoints.MATERIALS);

export const getDeliveryAddresses =
  (): AxiosPromise<IDeliveryAddressResponse> =>
    axiosInstance.get(MarketplaceEndpoints.DELIVERY_ADDRESS);

  export const getOrdersList =
  (): AxiosPromise<IOrdersListResponse> =>
    axiosInstance.get("/orders");

  export const getSingleOrder = (
    id: string
  ): AxiosPromise<ISingleOrderResponse> => axiosInstance.get(`/order/${id}`);

  export const getSingleSupplierOrder = (
    id: string
  ): AxiosPromise<ISingleSupplierOrderResponse> => axiosInstance.get(`/order/supplier-order/${id}`);

  export const cancelOrder = (id: string): AxiosPromise<undefined> =>
    axiosInstance.post(`/order/cancel-supplier-order/${id}`);

  export const sendOrder = (params: IOrderRequest): AxiosPromise<undefined> =>
    axiosInstance.post("/order/create", params);