import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, Icon, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { Title } from "../lib/title/Title";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { ReactComponent as DeleteIcon } from "../../images/svg/delete/delete-active.svg";
import { useEffect, useMemo, useState } from "react";
import { IMarketplaceMaterialOffer, IOrderRequest } from "../../api/marketplace/types";
import { CellContext, ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { formatAmount } from "../../helpers/formatAmount";
import {
    updateMaterialOffer,
    clearOffersQuantity,
    MarketplaceNDS,
    fetchSendOrder,
    getObjectsFromLocalStorage,
    fetchAllMaterials,
    saveObjectsToLocalStorage,
    fetchOrdersList
} from "../../store/slices/marketplace";
import { Table } from "../lib/table/Table";
import { AddressDropDown } from "./AdressDropdown";
import { PrimaryButton } from "../lib/button/Button";
import { FormikProps, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { FullScreenWaitingModal } from "../Modal/FullScreenWaitingModal";
import { ChakraModalProps } from "../../shared/types/modalProps";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

const columnHelper = createColumnHelper<IMarketplaceMaterialOffer>();

type cellProps = {
    info: CellContext<IMarketplaceMaterialOffer, string>;
};

const ClearCell = ({ info }: cellProps) => {
    const dispatch = useAppDispatch();
    let myOffers = getObjectsFromLocalStorage()
    const changeValue = (e: string) => {
        const rawValue = e.replace(/\s/g, "").replace(",", ".");
        const floatValue = parseFloat(rawValue);
        const newValue = Number.isNaN(floatValue) ? "0,00" : formatAmount(floatValue);

        if (newValue !== info.row.original.quantity && floatValue <= parseFloat(info.row.original.amount)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = newValue;
            copiedOffer.totalPrice = formatAmount(
                floatPrice * floatValue
            );
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        } else if (floatValue > parseFloat(info.row.original.amount)) {
        }
    };
    return (
        <Button minWidth="auto" width="32px" backgroundColor="transparent" onClick={() => changeValue("0,00")} height="32px" p="6px" border={`1px solid #F1868B`} borderRadius="6px">
            <Icon width="20px" height="20px" as={DeleteIcon} />
        </Button>)
}

const QuantityCell = ({ info }: cellProps) => {
    const dispatch = useAppDispatch();

    const initialQuantity = info.row.original.quantity;

    const [isError, setIsError] = useState<boolean>(false)
    const [value, setValue] = useState(() => {
        const nextNumber = parseFloat(initialQuantity);
        return Number.isNaN(nextNumber) ? "0,00" : formatAmount(nextNumber);
    });

    useEffect(() => {
        const nextNumber = parseFloat(initialQuantity);
        setValue(Number.isNaN(nextNumber) ? "0,00" : formatAmount(nextNumber));
        setIsError(Number(info.row.original.quantity) > Number(info.row.original.amount))
    }, [initialQuantity, info.row.original.amount]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        changeValue(e.target.value)
    }


    const changeValue = (e: string) => {
        const rawValue = e.replace(/\s/g, "").replace(",", ".");
        const floatValue = parseFloat(rawValue);
        const newValue = Number.isNaN(floatValue) ? "0,00" : formatAmount(floatValue);

        if (newValue !== info.row.original.quantity && (floatValue <= parseFloat(info.row.original.amount) || Number.isNaN(floatValue)) && !(floatValue < 0)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = Number.isNaN(floatValue) ? "0,00" : String(floatValue);
            if (Number.isNaN(floatValue)) {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            } else {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * floatValue
                );
            }

            setIsError(false)
            let myOffers = getObjectsFromLocalStorage()
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        } else if (floatValue > parseFloat(info.row.original.amount)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = Number.isNaN(floatValue) ? "0,00" : String(floatValue);
            if (Number.isNaN(floatValue)) {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            } else {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * floatValue
                );
            }

            setIsError(true)
            let myOffers = getObjectsFromLocalStorage()
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        } else if (floatValue < 0 || Number.isNaN(floatValue)) {
            const copiedOffer = { ...info.row.original };
            const floatPrice = parseFloat(copiedOffer.price.replace(/\s/g, "").replace(",", "."))
            copiedOffer.quantity = "0,00";
            if (Number.isNaN(floatValue)) {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            } else {
                copiedOffer.totalPrice = formatAmount(
                    floatPrice * 0
                );
            }

            setIsError(true)
            let myOffers = getObjectsFromLocalStorage()
            myOffers.push(copiedOffer)
            saveObjectsToLocalStorage(myOffers)
            dispatch(updateMaterialOffer({
                offer: copiedOffer,
                flag: false
            }));
        }
    };

    const getInputBorderColor = () => {
        if (isError) { return "#E8363D" }
        if (value !== "0,00") { return "#AAB0C1" }
        else { return "#E3E7EF" }
    }

    return (
        <Flex key={`${info.row.original.guid}`} alignItems="center" gap="8px" flexDirection="column"
        >
            <Input
                height="32px"
                width="100px"
                value={value}
                border="1px solid #E3E7EF"
                borderColor={getInputBorderColor()}
                onChange={handleChange}
                onBlur={handleBlur}
                errorBorderColor="#E8363D"
                data-tooltip-id={isError ? "tooltip" : ""}
                isDisabled={info.row.original.quantity > info.row.original.amount && Number(info.row.original.amount) === 0}
                data-tooltip-html={info.row.original.quantity > info.row.original.amount && Number(info.row.original.amount) !== 0
                    ? "Укажите доступное количество"
                    : info.row.original.quantity > info.row.original.amount && Number(info.row.original.amount) === 0 ? "Материал пока недоступен для заказа. Удалите его" : null}
            />
        </Flex>
    );
};

const NdsPrice = ({ info }: cellProps) => {
    const price = info.row.original.totalPrice;
    if (price) {
        const rawValue = price.replace(/\s/g, "").replace(",", ".");
        const floatValue = parseFloat(rawValue);
        const ndsPrice = floatValue * (1 + MarketplaceNDS)
        return <Box>{formatAmount(ndsPrice)}</Box>
    } else {
        return <Box>0,00</Box>
    }

}

export const MarketplaceCart = () => {
    const dispatch = useAppDispatch()
    const [flatOffers, setFlatOffers] = useState<IMarketplaceMaterialOffer[]>()

    const navigate = useNavigate();

    const { materials, totalSum } = useAppSelector(
        (state: RootState) => state.marketplace.marketplaceData
    );

    const isDisabledButtons = false;

    const formik: FormikProps<IOrderRequest> =
        useFormik<IOrderRequest>({
            initialValues: {
                address_guid: "",
                comment: "",
                materials: flatOffers?.map((material) => {
                    return {
                        guid: material.guid,
                        amount: Number(material.quantity),
                        supplier_guid: material.supplier_guid
                    }
                }) ?? []
            },
            onSubmit: () => { },
        });

    useEffect(() => {
        let allOffers: IMarketplaceMaterialOffer[] = [];
        materials?.forEach(el => {
            el.materials.forEach(el => {
                allOffers = [...allOffers, ...(el.offers.filter((offer) => {
                    return (!!offer.quantity && offer.quantity !== "0,00" && offer.quantity !== "0" && offer.quantity !== "0,0")
                }))]
            })
        }
        )
        setFlatOffers(allOffers)
    }, [materials]);
    useEffect(() => {
        formik.setFieldValue("materials", flatOffers?.map((material) => {
            return {
                guid: material.guid,
                amount: material.quantity,
                supplier_guid: material.supplier_guid
            }
        }) ?? [])
    }, [flatOffers])


    const columns = useMemo<Array<ColumnDef<IMarketplaceMaterialOffer, string>>>(
        () => [
            columnHelper.accessor("supplier_name", {
                header: "Поставщик",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.accessor("name", {
                header: "Материал",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.accessor("article", {
                header: "Артикул",
                cell: (info) => info.renderValue(),
            }),
            columnHelper.accessor("price", {
                header: "Цена",
                cell: (info) => formatAmount(String(info.getValue())),
            }),
            columnHelper.accessor("amount", {
                header: "Остаток",
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor("quantity",
                {
                    header: "Количество",
                    cell: (info) => (
                        <QuantityCell
                            info={info}
                        />
                    ),
                }
            ),
            columnHelper.display(
                {
                    header: "Сумма с НДС",
                    cell: (info) => <NdsPrice info={info} />,
                }
            ),
            columnHelper.display(
                {
                    header: "НДС",
                    cell: (info) => <Box>20%</Box>
                }
            ),
            columnHelper.accessor("totalPrice",
                {
                    header: "Сумма без НДС",
                    cell: (info) => info.getValue() || "0,00",
                }
            ),
            columnHelper.display(
                {
                    id: "deleteColumn",
                    header: () => (
                        <Button
                            minWidth="auto"
                            width="32px"
                            backgroundColor="transparent"
                            height="32px"
                            p="6px"
                            border={`1px solid #F1868B`}
                            borderRadius="6px"
                            onClick={() => {
                                dispatch(clearOffersQuantity())
                                localStorage.removeItem("offers")
                            }}
                            data-tooltip-id="tooltip"
                            data-tooltip-content="Очистить всё"
                        >
                            <Icon width="20px" height="20px" as={DeleteIcon} />
                        </Button>
                    ),
                    cell: (info) => <ClearCell info={info} />,

                }
            ),
        ],
        []
    );

    const [isSpinnerRunning, setIsSpinnerRunning] = useState<boolean>(false)
    const [selectedAddress, setSelectedAddress] = useState<string>("");

    const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();

    const handleSubmit = () => {
        setIsSpinnerRunning(true)
        dispatch(fetchSendOrder(formik.values))
            .then((res: any) => {
                if (res.payload.success) {
                    onSuccessOpen()
                    formik.setFieldValue("address_guid", "")
                    formik.setFieldValue("comment", "")
                    setSelectedAddress("")
                    dispatch(fetchOrdersList())
                }
            })
            .finally(() => setIsSpinnerRunning(false))
    }

    let myObjects = getObjectsFromLocalStorage();

    const [isRender, setIsRender] = useState<boolean>(false)

    useEffect(() => {
        dispatch(fetchAllMaterials())
            .finally(() => {
                if (myObjects.length > 0 && !isRender) {
                    myObjects.forEach((el: any) => dispatch(updateMaterialOffer({
                        offer: el,
                        flag: true
                    })))
                    setIsRender(true)
                }
            })
    }, [])

    return (
        <Flex height={"100%"} backgroundColor="white" overflowY="scroll" maxH="100%" direction="column" minH="full">

            <Flex marginBottom="24px" justifyContent="space-between" alignItems="center" gap="16px" marginLeft="1px">
                <AddressDropDown isDisabled={isDisabledButtons} formik={formik} selectedAdress={selectedAddress} setSelectedAdress={setSelectedAddress}/>
                <InputGroup border={"1px solid #E3E7EF"} width="20vw" borderRadius="6px">
                    <Input id="comment" value={formik.values.comment} onChange={formik.handleChange} background={isDisabledButtons ? "#F0F3FA" : ""} border="none" h="44px" isDisabled={isDisabledButtons} sx={{
                        opacity: "1 !important",
                        borderColor: "none !important",
                        boxShadow: "none !important"
                    }} w="15vw" />
                    <InputRightElement pointerEvents="none" color="#8089A2" fontSize="12px" fontWeight={400} marginRight="40px">
                        Комментарий
                    </InputRightElement>
                </InputGroup>

                <Flex width="fit-content">
                    <Title fontSize="16px" fontWeight="400" marginRight="8px" color="#8089A2" whiteSpace="nowrap">
                        Сумма заказа (с НДС):
                    </Title>
                    <Title fontSize="16px" fontWeight="400" whiteSpace="nowrap">{totalSum}</Title>
                </Flex>
                <PrimaryButton
                    isDisabled={formik.values.address_guid === ""
                        || formik.values.materials.length === 0 ||
                        flatOffers?.some((el) => Number(el.amount) === 0) ||
                        flatOffers?.some((el) => Number(el.quantity) > Number(el.amount))} onClick={handleSubmit}>Заказать</PrimaryButton>
            </Flex>

            <Box height={"100%"}>
                <Table
                    dblClickDisabled={true}
                    minHeight={"100%"}
                    style={{ "height": "70vh" }}
                    columns={columns} data={flatOffers || []} isCartTable={true}> </Table>
            </Box>
            <FullScreenWaitingModal openModal={isSpinnerRunning} />
            <SuccessModal isOpen={isSuccessOpen} onClose={onSuccessClose} address={selectedAddress} />
        </Flex>
    );
};


interface SuccessModalProps extends ChakraModalProps {
    address: string
}

const SuccessModal = ({ onClose, isOpen, address }: SuccessModalProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    return (
        <Modal isCentered isOpen={isOpen} onClose={() => {
            onClose();
            dispatch(clearOffersQuantity())
            dispatch(fetchAllMaterials())
            localStorage.removeItem("offers")
            navigate("/marketplace")
        }}>
            <ModalOverlay backdropFilter="blur(3px)" />
            <ModalContent>
                <ModalHeader>
                    <Text fontSize="24px"
                        color="#1F222A"
                        lineHeight="28px"
                        letterSpacing="-1.5%"
                        textAlign="left"
                        fontWeight={500}>Заказ успешно создан</Text>
                </ModalHeader>
                <ModalBody>
                    <Text color="#5C657E" fontSize="16px">
                        {`Ваш заказ был успешно оформлен и будет доставлен по адресу ${address}`}
                    </Text>
                </ModalBody>
                <ModalFooter>
                    <PrimaryButton onClick={() => {
                        onClose();
                        dispatch(clearOffersQuantity())
                        dispatch(fetchAllMaterials())
                        localStorage.removeItem("offers")
                        navigate("/marketplace")
                    }}>
                        Принять
                    </PrimaryButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
