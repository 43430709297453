import {
    Box,
    Flex,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";

import { Title } from "../lib/title/Title";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { MarketPlaceCatalog } from "./MatketplaceCatalog/MatketplaceCatalog";
import { ChakraTabs, ITab } from "../lib/tabs/Tabs";
import { useSearchParams } from "react-router-dom";
import { MarketplaceOrders } from "./MarketplaceOrders/MarketplaceOrders";
import { MarketplaceCart } from "../MarketplaceCartPage";
import { useEffect, useState } from "react";

export const marketplacePageTabs: ITab[] = [
    { id: 1, label: "Каталог" },
    { id: 2, label: "Корзина" },
    { id: 3, label: "Заказы" },
];

export const MarketplaceProducts = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(Number(searchParams.get("tab") || 0));

    useEffect(() => {
        const initialTabIndex = Number(searchParams.get("tab") || 0);
        setTabIndex(initialTabIndex);
    }, [searchParams]);


    const setDefaultTabParam = (index: number): void => {
        setTabIndex(index);
        if (index === 0) {
            searchParams.delete("tab");
        } else {
            searchParams.set("tab", String(index));
        }
        setSearchParams(searchParams);
    };

    return (
        <Flex height={"100%"} backgroundColor="white" overflowY="scroll" maxH="100%" p={6} direction="column" minH="full">
            <Title marginBottom="8px">Маркетплейс</Title>
            <Tabs index={tabIndex} onChange={setDefaultTabParam}>
                <ChakraTabs tabs={marketplacePageTabs} />
                <TabPanels>
                    <TabPanel px={0} pb={0}>
                        <MarketPlaceCatalog />
                    </TabPanel>
                    <TabPanel px={0} pb={0}>
                        <MarketplaceCart />
                    </TabPanel>
                    <TabPanel px={0} pb={0}>
                        <MarketplaceOrders />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Box>
                <OnboardingByPermission permission={AccessRights.Marketplace_RW} />
            </Box>
        </Flex>
    );
};