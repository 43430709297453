import { Outlet } from "react-router-dom";
import { RequireAuth } from "../../hoc/RequireAuth";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { useEffect } from "react";
import { useAppDispatch } from "../../store";
import { resetCurrentMaterial } from "../../store/slices/marketplace";

export const MarketplacePage = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetCurrentMaterial())
        }
    }, [])
    return (
        <RequireAuth>
            <MainLayout >
                <Outlet />
            </MainLayout>
        </RequireAuth>
    );
};
